<template>
  <div
    :ref="(el) => rootElement = el"
    class="grid-row"
  >
    <div
      class="_body"
    >
      <m-dropdown
        v-if="!props.readOnly && !isPrint"
        v-model:value="actionActivated"
        placement="topRight"
        :title="$t('general.select')"
        class="_dropdown"
      >
        <m-tooltip
          class="_tooltip"
          placement="top"
        >
          <div
            :class="['_column-btn', (!isResizing && focus) || actionActivated ? '-focus' : null]"
          >
            <m-icon type="plus" />
          </div>
          <template #title>
            {{ $t('gridPage.gridActions.addTooltip') }}
          </template>
        </m-tooltip>
        <template #overlay>
          <grid-actions
            :grid-row="row"
            :grid-page="props.gridPage"
            @close="actionActivated = false"
          />
        </template>
      </m-dropdown>
      <div
        :ref="(el) => itemElement = el"
        class="_items"
        :style="{ height: `${height}px` }"
      >
        <grid-item
          v-for="tile in tiles"
          :key="tile.uid"
          :grid-page-tile="tile"
          :grid-row="row"
          :read-only="props.readOnly"
          :container-width="itemContainerWidth"
          :height="height"
        />
      </div>
      <div
        v-if="!isPrint"
        class="_row-gap"
      >
        <div
          v-if="dragConfiguration.topVisible"
          class="_dragzone-indicator-top _indicator"
        />
        <div
          v-if="dragConfiguration.bottomVisible"
          class="_dragzone-indicator-bottom _indicator"
        />
        <div
          v-if="!(dragConfiguration.bottomEnabled || dragConfiguration.topEnabled) && canResize"
          :ref="(el) => rowResizeContainerRef = el"
          :class="['_row-resize-container', isResizingSelf ? '-resizing': '']"
          @mousemove="moveHighlight"
          @mousedown="initResize"
        >
          <div class="_indicator" />
          <div
            class="_indicator-highlight"
            :style="highlightStyle"
          />
        </div>
      </div>
      <div
        v-if="isResizingHorizontally && !isPrint"
        class="_resize-pips"
      >
        <div
          v-for="index in (maxColumns-1)"
          :key="`pip_${index}`"
          class="_pip-container"
        >
          <span :class="['_pip', isPipActive(index) ? '-visible':'']" />
        </div>
      </div>
      <div
        v-if="dragConfiguration.topEnabled && !isPrint"
        :style="{ top: '-2.6rem' }"
        class="grid-item-drop _dragzone"
        :data-id="JSON.stringify({uid: row.uid, position: 'top'})"
      />
      <div
        v-if="dragConfiguration.bottomEnabled && !isPrint"
        :style="{ bottom: '-1rem' }"
        class="grid-item-drop _dragzone"
        :data-id="JSON.stringify({uid: row.uid, position: 'below'})"
      />
    </div>
  </div>
</template>

<script setup>
import GridActions from '@/components/custom-grid/GridActions.vue';
import GridItem from '@/components/custom-grid/GridItem.vue';
import useDebounce from '@/composables/debounce';
import useExport from '@/composables/export/export';
import useGridPage from '@/composables/grid-page/grid-page';
import useGridPageConfig from '@/composables/grid-page/grid-page-config';
import useSnackBar from '@/composables/snackbar';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { sortByArray } from 'shared/lib/sort';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  gridPage: { type: Object, required: true },
  gridRow: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
});

const { isPrint } = useExport();
const gridPageService = useGridPage();
const debounce = useDebounce();
const snackBar = useSnackBar();

const { t } = useI18n();

const rootElement = ref(null);
const itemElement = ref(null);

const itemContainerWidth = ref(0);

const resizeObserver = new ResizeObserver(() => { itemContainerWidth.value = itemElement.value.clientWidth; });
onMounted(() => {
  resizeObserver.observe(itemElement.value);
});
onUnmounted(() => { resizeObserver.disconnect(); });

const gridPage = gridPageService.gridPage(props.gridPage.uid);
const row = computed(() => gridPageService.gridPageRows.value.find((row) => row.uid === props.gridRow.uid));
const tiles = computed(() => {
  if (row.value === undefined) {
    return [];
  }

  return row.value.tiles.map(({ uid }) => gridPageService.gridPageTiles.value.find((tile) => tile.uid === uid))
    .filter((tile) => tile !== undefined)
    .sort(sortByArray(row.value.tileOrder));
});

const height = computed(() => {
  const heightConfig = gridPageService.rowsWithDiff.value[row.value.uid];
  let height = heightConfig.height;
  if (heightConfig.diff !== null) {
    height += heightConfig.diff;
  }

  return height;
});

const actionActivated = ref(false);

const rowOrder = computed(() => gridPage.value.rowOrder.filter(({ uid }) => gridPageService.gridPageRows.value.find((row) => row.uid === uid) !== undefined));
const dragConfiguration = computed(() => {
  const res = {
    topEnabled: false,
    bottomEnabled: false,
    topVisible: false,
    bottomVisible: false,
  };

  if (gridPageService.dragItem.value === null) {
    return res;
  }

  res.topEnabled = rowOrder.value[0].uid === row.value.uid;
  res.bottomEnabled = true;

  const dragTarget = gridPageService.dragTarget.value;

  if (dragTarget === null) {
    return res;
  }

  if (dragTarget.uid !== row.value.uid) {
    return res;
  }

  switch (dragTarget.position) {
    case 'top':
      res.topVisible = true;
      break;
    case 'below':
      res.bottomVisible = true;
      break;
    default:
      break;
  }

  return res;
});

const cursorPos = ref({ init: null, next: null });

const canResize = !props.readOnly;
const { isResizing, maxColumns } = gridPageService;
const isResizingSelf = computed(() => {
  if (!isResizing.value || gridPageService.rowResizing.value === null) {
    return false;
  }
  return gridPageService.rowResizing.value.uid === row.value.uid;
});
const isResizingHorizontally = computed(() => {
  if (!isResizing.value) {
    return false;
  }
  return tiles.value.some((t) => gridPageService.tilesResizing.value.includes(t.uid));
});

const initResize = (event) => {
  if (cursorPos.value.init !== null) {
    return;
  }

  cursorPos.value.init = event.clientY;
  document.addEventListener('mousemove', handleMouseMove);
  document.addEventListener('mouseup', commit);
};

const handleMouseMove = (event) => {
  event.stopPropagation();
  const fn = () => {
    cursorPos.value.next = event.clientY;
    gridPageService.addHeightDiff(row.value, cursorPos.value.next - cursorPos.value.init);
  };
  debounce.debounce(fn, 5);
};

const commit = async () => {
  debounce.cancelAll();
  document.removeEventListener('mousemove', handleMouseMove);
  document.removeEventListener('mouseup', commit);
  cursorPos.value = { init: null, next: null };

  const response = gridPageService.commitHeight(row.value);
  response.catch(() => { snackBar.error(t('error.default')); });
};

const focus = ref(false);

watch(rootElement, () => {
  rootElement.value.addEventListener('mouseenter', () => {
    focus.value = true;
  });
  rootElement.value.addEventListener('mouseleave', () => {
    focus.value = false;
  });
});

const { indicatorHighlightSize: highlightWidth, minColumns } = useGridPageConfig();
const rowResizeContainerRef = ref(null);
const rowResizeContainerRect = computed(() => {
  if (rowResizeContainerRef.value === null) return { x: 0, width: 0 };
  return rowResizeContainerRef.value.getBoundingClientRect();
});
const highlightStyle = ref({ width: `${highlightWidth}px`, left: 0 });
const moveHighlight = (event) => {
  const minCenter = 0;
  const maxCenter = rowResizeContainerRect.value.width - highlightWidth;
  const containerLeftX = rowResizeContainerRect.value.x;
  let center = event.clientX - containerLeftX - highlightWidth / 2;
  if (center < minCenter) { center = minCenter; }
  if (center > maxCenter) { center = maxCenter; }
  highlightStyle.value = { width: `${highlightWidth}px`, left: `${center}px` };
};

const mapGapToPip = computed(() => [0, ...tiles.value.reduce((acc, next, i) => {
  let current = next.columns;
  if (i > 0) {
    current += acc[i - 1];
  }
  acc.push(current);
  return acc;
}, [])]);
const pipMinMax = computed(() => {
  const resizingIndexes = gridPageService.tilesResizing.value.map((trId) => tiles.value.findIndex((t) => t.uid === trId));
  const smallestIndex = resizingIndexes.length > 0 ? Math.min(...resizingIndexes) : -1;
  if (smallestIndex === -1) {
    return { min: mapGapToPip.value[0] + minColumns, max: mapGapToPip.value[mapGapToPip.value.length - 1] - minColumns };
  }
  let gapPip = 0;
  for (let i = 0; i <= smallestIndex; i++) {
    gapPip += tiles.value[i].columns;
  }
  const i = mapGapToPip.value.findIndex((el) => el === gapPip);
  return { min: mapGapToPip.value[i - 1] + minColumns, max: mapGapToPip.value[i + 1] - minColumns };
});
const isPipActive = (pipIndex) => {
  if (!isResizingHorizontally.value) {
    return false;
  }
  return pipMinMax.value.min <= pipIndex && pipIndex <= pipMinMax.value.max;
};

</script>

<style lang="scss" type="text/scss" scoped>
  .grid-row {
    width: 100%;
    display: flex;

    ._column-btn {
      position: relative;
      border-radius: $default-border-radius;
      width: 2.8rem;
      display: flex;
      justify-content: center;
      cursor: pointer;
      color: transparent;

      &.-focus {
        color: map_get($grey, 'lighten-1');

        &:hover {
          background-color: map_get($grey, 'lighten-5');
        }
      }
    }

    ._body {
      position: relative;
      width: 100%;

      ._items {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
      }

      ._dropdown {
        position: absolute;
        top: 0;
        left: -4rem;
        min-height: calc(100% - 2rem);

        ._tooltip {
          min-height: 100%;
        }
      }

      ._dragzone {
        height: 4rem;
        position: absolute;
        left: 0;
        width: 100%;
      }

      ._indicator {
        position: relative;
        top: .8rem;
        width: 100%;
        height: .4rem;
        border-radius: $border-radius-sm;
        opacity: .2;
      }

      ._indicator-highlight {
        position: relative;
        top: .3rem;
        height: .6rem;
        border-radius: $border-radius-sm;
      }

      ._dragzone-indicator-top {
        position: absolute;
        top: -1.2rem;
        width: calc(100% - 2rem);
        background-color: map_get($blue, 'base');
      }

      ._dragzone-indicator-bottom {
        background-color: map_get($blue, 'base');
      }

      ._row-gap {
        height: 2rem;
        width: calc(100% - 2rem);

        ._row-resize-container {
          height: 100%;
          width: 100%;

          &:hover, &.-resizing {
            cursor: ns-resize;

            ._indicator, ._indicator-highlight {
              background-color: map_get($blue, 'base');
            }
          }
        }
      }

      ._resize-pips {
        position: absolute;
        top: -1rem;
        left: 0;
        width: calc(100% - 2rem);
        display: flex;
        justify-content: space-evenly;

        ._pip-container {
          display: flex;
          justify-content: center;
          width: 20px;

          ._pip {
            background-color: transparent;
            border-radius: 50%;
            height: 6px;
            width: 6px;

            &.-visible {
              background-color: map_get($grey, 'lighten-3');
            }
          }
        }
      }
    }

  }

</style>
