<template>
  <m-card
    :level="1"
    :flat="tile.hideBorder"
    :bordered="!tile.hideBorder && isPrint"
    no-padding
    :class="['grid-item-content']"
  >
    <div class="_card-body">
      <grid-item-header-single-goal
        v-if="gridPageTile.type === gridPageType.singleGoal"
        :tile="tile"
        :grid-row="gridRow"
        :read-only="props.readOnly"
      />
      <grid-item-header
        v-else
        :tile="tile"
        :grid-row="gridRow"
        :read-only="props.readOnly"
        :hide-title="tile.type === gridPageType.text"
        :hide-description="tile.type === gridPageType.text"
        :can-edit-border="tile.type === gridPageType.text"
      />

      <grid-page-tile-text
        v-if="gridPageTile.type === gridPageType.text"
        :grid-page-tile="tile"
        :read-only="props.readOnly"
      />
      <grid-page-tile-goal-list
        v-else-if="gridPageTile.type === gridPageType.goalList"
        :grid-page-tile="tile"
        :read-only="props.readOnly"
      />
      <grid-page-tile-checkin-list
        v-else-if="gridPageTile.type === gridPageType.checkinList"
        :grid-page-tile="tile"
        :read-only="props.readOnly"
      />
      <grid-page-tile-single-goal
        v-else-if="gridPageTile.type === gridPageType.singleGoal"
        :grid-page-tile="tile"
        :read-only="props.readOnly"
      />
      <grid-page-tile-update-feed
        v-else-if="gridPageTile.type === gridPageType.updateFeedList"
        :grid-page-tile="tile"
        :read-only="props.readOnly"
      />
      <grid-page-tile-member-list
        v-else-if="gridPageTile.type === gridPageType.memberList"
        :grid-page-tile="tile"
        :read-only="props.readOnly"
      />
    </div>
  </m-card>
</template>

<script setup>

import GridItemHeader from '@/components/custom-grid/GridItemHeader.vue';
import GridItemHeaderSingleGoal from '@/components/custom-grid/GridItemHeaderSingleGoal.vue';
import GridPageTileCheckinList from '@/components/custom-grid/GridPageTileCheckinList.vue';
import GridPageTileGoalList from '@/components/custom-grid/GridPageTileGoalList.vue';
import GridPageTileMemberList from '@/components/custom-grid/GridPageTileMemberList.vue';
import GridPageTileSingleGoal from '@/components/custom-grid/GridPageTileSingleGoal.vue';
import GridPageTileText from '@/components/custom-grid/GridPageTileText.vue';
import GridPageTileUpdateFeed from '@/components/custom-grid/GridPageTileUpdateFeed.vue';
import useExport from '@/composables/export/export';
import useGridPage from '@/composables/grid-page/grid-page';
import { gridPageType } from 'shared/constants.json';

const { isPrint } = useExport();

const props = defineProps({
  tile: {
    type: Object,
    required: true,
  },
  gridRow: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const gridPageSvc = useGridPage();
const gridPageTile = gridPageSvc.gridPageTiles.value.find((t) => t.uid === props.tile.uid);

</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>

  .grid-item-content {
    height: 100%;
    position: relative;
    background: white;

    ._card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &:hover {
      :deep(.grid-item-header) {
        ._actions {
          display: block;
        }
      }
    }
  }
</style>
