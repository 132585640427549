<template>
  <div
    id="bumblebee"
    class="main-layout"
  >
    <div
      v-if="isPrint"
      :style="{ margin: '0 3rem', maxWidth: 'calc(100vw - 6rem)'}"
    >
      <slot />
    </div>
    <template v-else>
      <m-layout
        v-if="$store.state.breakpoint.smAndDown"
        class="_mobile"
      >
        <div
          :class="['_sidebar', showMenu ? '-show' : '']"
          :style="{ 'background-color': '#f7f6f3' }"
          @close="toggle"
        >
          <main-navigation
            class="_nav"
            width="calc(100% - 5rem)"
          />
        </div>
        <div
          v-show="showMenu"
          class="_dragbar"
          @click.stop="showMenu = false"
          @touchstart="handleTouchStart"
          @touchend="handleTouchEnd"
          @touchmove="handleTouchMove"
        />
        <div
          :class="['_content', showMenu ? '-show' : '']"
          :style="mobileContentStyle"
        >
          <slot />
        </div>
        <mobile-bottom-menu />
      </m-layout>
      <m-layout
        v-else
      >
        <div
          v-if="desktopSidebarHidden"
          class="_toggle"
        >
          <m-btn
            icon="hamburger"
            hide-border
            fab
            icon-size="22"
            @click="toggleDesktopSidebarHidden"
          />
        </div>
        <main-navigation
          v-show="!desktopSidebarHidden || showTemporarily"
          :style="navigationStyle"
          @toggle-sidebar="toggleDesktopSidebarHidden"
        />
        <slot />
        <m-card
          v-if="showAppFeedback"
          class="_app-feedback"
          no-padding
        >
          <app-feedback
            @close="closeAppFeedback"
          />
        </m-card>
      </m-layout>
    </template>
  </div>
</template>

<script>
import AppFeedback from '@/components/AppFeedback.vue';
import MainNavigation from '@/components/navigation/MainNavigation.vue';
import MobileBottomMenu from '@/components/navigation/MobileBottomMenu.vue';
import useAppFeedback from '@/composables/app-feedback/app-feedback';
import useExport from '@/composables/export/export';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import { EventBus } from '@/lib/event-bus';
import { mapActions } from 'vuex';
import { personalAppSettings as personalAppSettingsConfig } from 'shared/api/query/configs.json';

export default {
  name: 'MainLayout',
  components: { AppFeedback, MainNavigation, MobileBottomMenu },
  data() {
    return {
      showMenu: false,
      showAppFeedback: false,
      showTemporarily: false,
      touchStart: 0,
      touchMove: 0,
      screenWidth: 0,
      showAfterDemoModal: false,
    };
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings, updateSingle } = usePersonalAppSettings(loggedInUser);
    const { canDisplayAppFeedback } = useAppFeedback();
    const { isPrint } = useExport();
    return {
      isPrint,
      loggedInUser,
      personalAppSettings,
      canDisplayAppFeedback,
      updatePersonalAppSettings: updateSingle,
    };
  },
  computed: {
    mobileContentStyle() {
      if (this.showMenu) {
        let diff = this.screenWidth - 50 - this.touchStart + this.touchMove;
        if (diff < 0) {
          diff = 0;
        }
        if (diff > this.screenWidth - 50) {
          diff = this.screenWidth - 50;
        }
        return {
          transform: `translateY(0) translateX(${diff}px)`,
          width: '100vw',
          overflow: 'hidden',
          position: 'fixed',
          backgroundColor: this.$route.meta.backgroundColor,
          zIndex: 1,
        };
      }

      return {
        width: '100vw',
        backgroundColor: this.$route.meta.backgroundColor,
        zIndex: 1,
      };
    },
    navigationStyle() {
      if (this.showTemporarily && this.desktopSidebarHidden) {
        return {
          position: 'absolute',
          top: '5vh',
          left: 0,
          marginBottom: '5vh',
          height: '90vh',
          zIndex: 99,
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          boxShadow: '0 1px 4px rgba(38, 50, 56, .2), 0 2px 8px rgba(38, 50, 56, .08)',
        };
      }

      return {};
    },
    desktopSidebarHidden() {
      return this.personalAppSettings[personalAppSettingsConfig.edges.desktopSidebarHidden];
    },
  },
  methods: {
    ...mapActions(['updateEntityV2']),
    handleTouchStart(event) {
      this.touchStart = event.touches[0].pageX;
      this.touchMove = event.touches[0].pageX;
    },
    handleTouchMove(event) {
      this.touchMove = event.touches[0].pageX;
    },
    handleTouchEnd() {
      if (Math.abs(this.touchMove - this.touchStart) > 100) {
        this.showMenu = false;
      }
      this.touchStart = 0;
      this.touchMove = 0;
    },
    toggle() {
      this.showMenu = !this.showMenu;
    },
    toggleDesktopSidebarHidden() {
      this.updatePersonalAppSettings({
        uid: this.personalAppSettings.uid,
        [personalAppSettingsConfig.edges.desktopSidebarHidden]: !this.desktopSidebarHidden,
      });
    },
    showTemp(event) {
      if (this.showTemporarily && event.clientX <= 240) {
        return;
      }

      if (event.clientX <= 10) {
        this.showTemporarily = true;
        return;
      }
      this.showTemporarily = false;
    },
    setScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    closeAppFeedback() {
      this.showAppFeedback = false;
    },
  },
  watch: {
    $route() {
      this.showMenu = false;
    },
    desktopSidebarHidden(val) {
      if (val) {
        document.getElementById('app').addEventListener('mousemove', this.showTemp);
        return;
      }
      document.getElementById('app').removeEventListener('mousemove', this.showTemp);
    },
  },
  created() {
    this.setScreenWidth();
    EventBus.$on('toggle-sidebar', this.toggle);
    window.addEventListener('resize', this.setScreenWidth);
    if (this.desktopSidebarHidden) {
      document.getElementById('app').addEventListener('mousemove', this.showTemp);
    }
  },
  mounted() {
    if (this.canDisplayAppFeedback()) {
      this.showAppFeedback = true;
    }
  },
  beforeUnmount() {
    EventBus.$off('toggle-sidebar', this.toggle);
    window.removeEventListener('resize', this.setScreenWidth);
    document.getElementById('app').removeEventListener('mousemove', this.showTemp);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._toggle {
    position: fixed;
    top: .6rem;
    left: .6rem;
    z-index: 1;
  }

  ._app-feedback {
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    width: 60rem;
    z-index: 1;
  }

  ._mobile {
    display: flex;
    overflow-x: hidden;

    ._sidebar {
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      width: 100vw;
      height: 100vh;

      ._nav {
        height: 100%;
      }
    }

    ._dragbar {
      position: fixed;
      top: 0;
      left: calc(100vw - 5rem);
      z-index: 9999;
      width: calc(100vw - 5rem);
      height: 100vh;
      overflow: hidden;
    }

    ._content {
      min-height: 100vh;
      background-color: white;
      box-shadow: rgba(15, 15, 15, .05) 0 0 0 1px, rgba(15, 15, 15, .1) 0 3px 6px, rgba(15, 15, 15, .2) 0 9px 24px;
      transition: transform .3s ease-in-out;

      &.-show {
        overflow-x: hidden;
        transition: transform .05s ease;
      }
    }
  }
</style>
