<template>
  <div class="goal-insights-performance-table">
    <m-table
      :key="groupBy"
      :columns="columns"
      :data-source="rows"
      :loading="loading"
      :pagination="pagination"
      :row-key="rowKey()"
      show-count
      @update:order="updateOrder"
    >
      <template #user="{ user }">
        <user-display
          class="_user"
          :user="user"
          small
          @click="handleUserClick(user)"
        />
      </template>
      <template #[spaceProperty.uid]="{ row }">
        <div
          class="_property-options"
        >
          <div
            v-for="option in row.userOptions.filter(op => op.property.uid === spaceProperty.uid)"
            :key="option.uid"
            class="_option"
          >
            <m-tag
              :icon="buildIconFromEntity(option)"
              :color="option.color"
              automatic-color-fallback
              small
              class="_tag"
              :title="textByLang(option.label, userLang)"
              @click="optionClick(option)"
            />
          </div>
        </div>
      </template>
      <template #propertyOption="{ propertyOption, row }">
        <hierarchical-property-option-table-cell
          :property-option="propertyOption"
          :row="row"
          :show-as-hierarchy="showAsHierarchy"
          @toggle-collapse="toggleCollapse(propertyOption.uid)"
        />
      </template>
      <template #publicGoals="{ publicGoals, row }">
        <div
          v-if="publicGoals !== undefined"
          class="_public-goals"
        >
          <m-btn
            hide-border
            small
            :disabled="publicGoals === 0 || loading"
            @click="showPublicGoals(row)"
          >
            <span class="_amount">{{ publicGoals }}</span>
          </m-btn>
          <m-tooltip :mouse-enter-delay=".5">
            <span
              v-if="showAsHierarchy && (row.hasChildren && row.publicGoalsSum > 0) && (publicGoals < row.publicGoalsSum)"
              class="_aggregated"
            >
              ({{ row.publicGoalsSum }})
            </span>
            <template #title>
              {{ $t('goalInsightsTable.subOfSubGoals') }}
            </template>
          </m-tooltip>
        </div>
      </template>
      <template #progressTitleRight>
        <div
          v-if="overallProgressExpected !== undefined"
        >
          <m-tooltip>
            <div
              class="_progress-title-right"
              @click.stop
            >
              {{ $t('goalInsightsTable.progressExpectedValueAbbreviation') }}:{{ overallProgressExpected }}%
            </div>
            <template #title>
              {{ $t('goalInsightsTable.progressExpectedValueTooltip') }}
            </template>
          </m-tooltip>
        </div>
      </template>
      <template #progress="{ progress, row }">
        <div
          v-if="row.publicGoals === 0"
          class="_text-cell"
        >
          -
        </div>
        <goal-insights-table-single-metric-cell
          v-else-if="progress !== undefined"
          :metric="progress"
          unit="%"
          :previous-metric="row.previousProgress"
          :metric-negative-threshold="overallProgressExpected"
          :delta-positive-threshold="1"
          :delta-negative-threshold="-1"
        />
      </template>
      <template #confidence="{ confidence, row }">
        <div
          v-if="row.publicGoals === 0"
          class="_text-cell"
        >
          -
        </div>
        <goal-insights-table-single-metric-cell
          v-else-if="confidence !== undefined"
          :metric="confidence"
          :minimum-fraction-digits="1"
          :previous-metric="row.previousConfidence"
          :delta-positive-threshold="0.1"
          :delta-negative-threshold="-0.1"
        />
      </template>
      <template #confidenceDistribution="{ confidenceDistribution, row }">
        <confidence-bar-chart
          v-if="confidenceDistribution !== undefined"
          class="_chart"
          height="1.4rem"
          round
          :distribution="confidenceDistribution"
          :disabled="loading"
          @distribution-click="handleConfidenceDistributionClick(row, $event)"
        />
      </template>
      <template #lastUpdate="{ lastUpdate }">
        <div
          class="_last-update"
        >
          <template v-if="lastUpdate === null">
            -
          </template>
          <time-diff
            v-else
            :timestamp="lastUpdate"
          />
        </div>
      </template>
    </m-table>
  </div>
</template>

<script>
import ConfidenceBarChart from '@/components/goal-insights/performance/ConfidenceBarChart.vue';
import GoalInsightsTableSingleMetricCell from '@/components/goal-insights/SingleMetricCell.vue';
import HierarchicalPropertyOptionTableCell from '@/components/goal-insights/HierarchicalPropertyOptionTableCell.vue';
import TimeDiff from '@/components/list/TimeDiff.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import statusProperty from '@/composables/goal/status-property';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useSpaces from '@/composables/space/spaces';
import useStatusDistribution from '@/composables/goal-insights/status-distribution';
import useUser from 'shared/composables/user';
import { AND } from 'shared/api/query/filter';
import { DateTime } from 'luxon';
import { breakdownActiveFilter, breakdownUserFilter } from '@/lib/goal-insights/breakdown-filter';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { getColor } from 'shared/lib/color-map';
import {
  goalInsightsGroupBy,
  routeName,
} from 'shared/constants.json';
import { guid } from 'shared/lib/uuid';
import { personalGoalsFilter } from '@/lib/filter/goal/personal-goals-filter';
import { teamGoalsFilter } from '@/lib/filter/goal/team-goals-filter';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalInsightsPerformanceTable',
  props: {
    insightsData: {
      type: Array,
      required: true,
    },
    groupByProperty: {
      type: Object,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentFilter: {
      type: Array,
      default: () => [],
    },
    groupBy: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    overallProgressExpected: {
      type: Number,
      default: undefined,
    },
  },
  emits: ['show-goal-list'],
  components: {
    HierarchicalPropertyOptionTableCell,
    ConfidenceBarChart,
    GoalInsightsTableSingleMetricCell,
    TimeDiff,
    UserDisplay,
  },
  setup(props) {
    const { goalSettings } = useGoalSettings();
    const { goalProperties, spaceProperty } = useProperties();
    const { userLang } = useLoggedInUser();
    const searchTerm = computed(() => (props.search));
    const { normalizedSpaces, setSpaceMappers, toggleCollapse } = useSpaces(searchTerm);
    const { statusProperty: goalStatusProperty } = useGoalProperty();
    const { options: statusOptions } = statusProperty(goalStatusProperty, userLang.value);
    const { loggedInUserAccount } = useLoggedInUserAccount();

    return {
      account: loggedInUserAccount,
      goalSettings,
      goalStatusProperty,
      normalizedSpaces,
      statusOptions,
      setSpaceMappers,
      toggleCollapse,
      userLang,
      statusLabel: textByLang(goalStatusProperty.value.label, userLang.value),
      goalProperties,
      spaceProperty,
    };
  },
  data() {
    return {
      getColor,
      textByLang,
      goalInsightsGroupBy,
      pageSize: 40,
      lastOrder: {},
    };
  },
  computed: {
    pagination() {
      if (this.insightsData.length < this.pageSize) {
        return false;
      }

      return { pageSize: this.pageSize };
    },
    showAsHierarchy() {
      const keys = Object.keys(this.lastOrder);
      return !(keys.length === 1 && this.lastOrder[keys[0]].length !== 0);
    },
    columns() {
      const cols = [];
      switch (this.groupBy) {
        case goalInsightsGroupBy.user:
          cols.push(...[
            {
              key: 'user',
              dataIndex: 'user',
              title: this.$t('goalInsightsTable.user'),
              scopedSlots: { customRender: 'user' },
              sorter: this.userSorter(),
            },
            {
              key: this.spaceProperty.uid.toString(),
              dataIndex: this.spaceProperty.uid.toString(),
              title: textByLang(this.spaceProperty.label, this.userLang),
              scopedSlots: { customRender: this.spaceProperty.uid },
              sorter: this.userPropertiesSorter,
            },
          ]);
          break;
        case goalInsightsGroupBy.space:
        case goalInsightsGroupBy.propertyOption:
          cols.push(...[
            {
              key: 'propertyOption',
              dataIndex: 'propertyOption',
              title: textByLang(this.groupByProperty.label, this.userLang),
              scopedSlots: { customRender: 'propertyOption' },
              sorter: (a, b) => textByLang(a.propertyOption.label, this.userLang).localeCompare(textByLang(b.propertyOption.label, this.userLang)),
            },
          ]);
          break;
        default:
          return [];
      }
      cols.push({
        key: 'publicGoals',
        dataIndex: 'publicGoals',
        title: this.$t('goalInsightsTable.publicGoals', { title: this.goalSettings.featureNamePlural }),
        scopedSlots: { customRender: 'publicGoals' },
        sorter: this.numberSorter('publicGoals'),
      });
      cols.push({
        key: 'currentProgress',
        dataIndex: 'currentProgress',
        title: this.$t('goalInsightsTable.progress'),
        scopedSlots: {
          titleRight: 'progressTitleRight',
          customRender: 'progress',
        },
        sorter: this.numberSorter('currentProgress'),
      });
      cols.push({
        key: 'currentConfidence',
        dataIndex: 'currentConfidence',
        title: this.$t('goalInsightsTable.confidence'),
        scopedSlots: { customRender: 'confidence' },
        sorter: this.numberSorter('currentConfidence'),
      });
      cols.push({
        key: 'confidenceDistribution',
        dataIndex: 'confidenceDistribution',
        title: this.$t('goalInsightsTable.confidenceDistribution', { title: this.statusLabel }),
        scopedSlots: { customRender: 'confidenceDistribution' },
      });
      // TODO: Remove when engagement dashboard goes public
      cols.push({
        key: 'lastUpdate',
        dataIndex: 'lastUpdate',
        title: this.$t('goalInsightsTable.lastUpdate'),
        scopedSlots: { customRender: 'lastUpdate' },
        sorter: this.lastUpdateSorter,
      });
      return cols;
    },
    rows() {
      if (this.groupBy === goalInsightsGroupBy.user) {
        return breakdownUserFilter(this.insightsData, this.search).map(this.mapInsightsData);
      }

      const setInsightsData = (node) => ({
        ...node,
        data: this.insightsData.find((c) => c.propertyOption.uid === node.uid),
        children: node.children.map(setInsightsData),
      });

      const hasData = (res, node) => node.data !== undefined || res || node.children.reduce(hasData, false);
      const mapContainsData = (node) => ({
        ...node,
        containsData: node.children.reduce(hasData, false),
        children: node.children.map(mapContainsData),
      });

      const aggregatePublicGoals = (res, node) => {
        if (node.data !== undefined && node.match) {
          res += node.data.publicGoals;
        }
        return res + node.children.reduce(aggregatePublicGoals, 0);
      };
      const mapPublicGoalsSum = (node) => ({
        ...node,
        publicGoalsSum: node.children.reduce(aggregatePublicGoals, node.data !== undefined ? node.data.publicGoals : 0),
        children: node.children.map(mapPublicGoalsSum),
      });

      const hasDataMatch = (res, node) => (node.data !== undefined && node.match) || res || node.children.reduce(hasDataMatch, false);
      const mapContainsDataMatch = (node) => ({
        ...node,
        containsDataMatch: node.children.reduce(hasDataMatch, false),
        children: node.children.map(mapContainsDataMatch),
      });

      this.setSpaceMappers([setInsightsData, mapContainsData, mapPublicGoalsSum, mapContainsDataMatch]);
      return this.normalizedSpaces.map((s) => ({
        ...s,
        propertyOption: this.spaceToPropertyOption(s),
        ...this.mapInsightsData(s.data),
        disabled: !s.match || s.data === undefined,
        hasChildren: s.children.length > 0,
      })).reduce(breakdownActiveFilter(this.showAsHierarchy), []);
    },
  },
  methods: {
    buildIconFromEntity,
    spaceToPropertyOption(space) {
      return {
        ...space,
        icon: buildIconFromEntity(space),
        label: { en: space.title, de: space.title },
        property: this.spaceProperty,
      };
    },
    userDisplayName(user) {
      return useUser(ref(user)).displayName.value;
    },
    goalListTitle(distributionItem) {
      return this.$t('goalInsightsPerformance.goalsByStatus', { title: this.goalSettings.featureNamePlural, statusLabel: this.statusLabel, label: distributionItem.x });
    },
    updateOrder(event) {
      this.lastOrder = event;
    },
    mapInsightsData(e) {
      if (e === undefined) {
        return {
          publicGoals: undefined,
          overallProgressExpected: undefined,
          currentProgress: undefined,
          previousProgress: undefined,
          currentConfidence: undefined,
          previousConfidence: undefined,
          confidenceDistribution: undefined,
          lastUpdate: null,
        };
      }
      let previousProgressList = [];
      let previousProgress;
      let previousConfidence;
      if (typeof e.compareToProgress !== 'undefined') {
        previousProgressList = e.compareToProgress.list;
        previousProgress = previousProgressList.length !== 0 ? this.averageProgress(previousProgressList) : null;
        previousConfidence = previousProgressList.length !== 0 ? this.averageConfidence(previousProgressList) : null;
      }

      const {
        distribution: confidenceDistribution,
        update: updateDistribution,
        updateDiff: updateDistributionDiff,
      } = useStatusDistribution(this.goalStatusProperty, this.statusOptions, this.userLang);

      updateDistribution(e.currentProgress.list.map((e) => e.statusOption.uid));
      updateDistributionDiff(previousProgressList.map((e) => e.statusOption.uid));

      return {
        ...e,
        currentProgress: this.averageProgress(e.currentProgress.list),
        previousProgress,
        currentConfidence: this.averageConfidence(e.currentProgress.list),
        previousConfidence,
        confidenceDistribution: confidenceDistribution.value,
      };
    },
    averageProgress(list) {
      let value = 0.0;
      if (list.length > 0) {
        value = list.reduce((acc, e) => acc + e.progress, 0.0) / list.length;
      }
      return Math.round(value);
    },
    averageConfidence(list) {
      if (list.length === 0) {
        return undefined;
      }
      const avg = list.reduce((acc, e) => acc + e.statusOption.score, 0.0) / list.length;
      return Math.round(avg * 10) / 10;
    },
    lastUpdateSorter(a, b) {
      if (a.lastUpdate === null) {
        return 1;
      }

      if (b.lastUpdate === null) {
        return -1;
      }

      const dateA = DateTime.fromISO(a.lastUpdate);
      const dateB = DateTime.fromISO(b.lastUpdate);

      const diff = dateA.diff(dateB);
      if (diff === 0) {
        return 0;
      }

      if (diff > 0) {
        return 1;
      }

      return -1;
    },
    numberSorter(key) {
      return (a, b) => a[key] - b[key];
    },
    userPropertiesSorter(a, b) {
      for (let i = 0; i < a.userOptions.length; i++) {
        if (typeof b.userOptions[i] === 'undefined') {
          return 1;
        }

        const r = textByLang(a.userOptions[i].label, this.userLang).localeCompare(textByLang(b.userOptions[i].label, this.userLang));
        if (r !== 0) {
          return r;
        }
      }

      return 0;
    },
    userSorter() {
      return (a, b) => this.userDisplayName(a.user).localeCompare(this.userDisplayName(b.user));
    },
    showGoalList(title, extraFilters) {
      this.$emit('show-goal-list', {
        filter: {
          account: { uid: this.account.uid },
          op: AND,
          children: [
            ...this.currentFilter,
            ...extraFilters,
          ],
        },
        title,
      });
    },
    titlePrefixed(record, title) {
      if (this.groupBy === goalInsightsGroupBy.user) {
        const displayName = this.userDisplayName(record.user);
        return `${displayName} - ${title}`;
      }
      return `${textByLang(record.propertyOption.label, this.userLang)} - ${title}`;
    },
    goalsByStatusOptionsFilter(selectedOptions) {
      return {
        account: { uid: this.account.uid },
        op: AND,
        children: [
          {
            key: guid(),
            op: AND,
            children: [],
            scope: {
              property: this.goalStatusProperty,
              selectedOptions,
            },
          },
        ],
      };
    },
    filterPerGroupBy(record) {
      if (this.groupBy === goalInsightsGroupBy.user) {
        return personalGoalsFilter({
          goalProperties: this.goalProperties,
          user: record.user,
          account: { uid: this.account.uid },
        });
      }
      if (this.groupBy === goalInsightsGroupBy.space) {
        return teamGoalsFilter({
          spaceProperty: this.spaceProperty,
          team: record.propertyOption,
        });
      }
      return null;
    },
    showPublicGoals(record) {
      this.showGoalList(
        this.titlePrefixed(record, `${this.$t('goalInsightsTable.publicGoals', { title: this.goalSettings.featureNamePlural })}`),
        [
          this.filterPerGroupBy(record),
        ],
      );
    },
    handleConfidenceDistributionClick(record, distributionItem) {
      this.showGoalList(
        this.titlePrefixed(record, this.goalListTitle(distributionItem)),
        [
          this.filterPerGroupBy(record),
          this.goalsByStatusOptionsFilter([{ uid: distributionItem.uid }]),
        ],
      );
    },
    handleUserClick(user) {
      this.$router.push({ name: routeName.profile, params: { userId: user.uid } });
    },
    optionClick(option) {
      this.$router.push({ name: routeName.spaceDetails, params: { optionId: option.uid } });
    },
    rowKey() {
      if (this.groupBy === goalInsightsGroupBy.user) {
        return 'user.uid';
      }
      return 'propertyOption.uid';
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  @import 'shared/assets/scss/padding';

  .goal-insights-performance-table {
    ._text-cell {
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }

    ._user {
      cursor: pointer;
    }

    ._property-options {
      display: flex;
      flex-wrap: wrap;

      ._option {
        display: flex;
        align-items: center;
        margin: .2rem 1.2rem .2rem 0;
        white-space: nowrap;
        cursor: pointer;

        ._avatar {
          margin-right: .6rem;
        }
      }
    }

    ._chart {
      width: 100%;
    }

    ._public-goals {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;

      ._aggregated {
        color: $font-color-secondary;
      }
    }

    ._last-update {
      white-space: nowrap;
    }

    ._progress-title-right {
      display: flex;
      align-items: center;
      padding-top: .4rem;
      font-size: $font-size-0;
      color: $font-color-tertiary;
      cursor: help;
    }

    .m-table {
      padding: 0 2.4rem;
      overflow: unset;
    }

    ._bottom {
      padding: 0 2.4rem;
    }
  }
</style>
