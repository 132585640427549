// chart Types
import { dateScopeDynamicType, dateScopeType } from 'shared/constants.json';

export const BAR = 'bar';
export const COLUMN = 'column';
export const SPLINE = 'spline';
export const AREASPLINE = 'areaspline';
export const LINE = 'line';
export const AREA = 'area';

// highcharts
export const DASH = 'Dash';
export const DOT = 'Dot';
export const SHORTDOT = 'ShortDot';
export const SHORTDASH = 'ShortDash';
export const DATETIME = 'datetime';

// dashboard types
export const ABSOLUTE = 'absolute';
export const DELTA = 'delta';

// Storage Keys
export const STORED_GOAL_PROPS = 'goal_props';
export const STORED_UPDATE_OPTION_PROPS = 'update_option_props';
export const STORED_GOAL_PROPS_PROFILE = 'goal_props_profile';
export const GOALS_EXPLORER_CYCLE_STORAGE_KEY = 'goals_explorer_cycles';
export const GOALS_EXPLORER_FILTER_STORAGE_KEY = 'goals_explorer_filter';
export const UNASSIGNED = 'unassigned';
export const STORED_UPDATE_PROPS = 'stored_update_props';
export const UPDATES_EXPLORER_FILTER_STORAGE_KEY = 'updates_explorer_filter';
export const UPDATES_EXPLORER_ORDER_STORAGE_KEY = 'updates_explorer_order';
export const UPDATES_EXPLORER_TYPE_STORAGE_KEY = 'updates_explorer_type';
export const NOTIFICATION_DIALOG_TEMPLATE = 'template';
export const NOTIFICATION_DIALOG_SCHEDULE = 'schedule';

// compare to previous week
export const ONE_DAY_AGO = 'one_day_ago';
export const ONE_WEEK_AGO = 'previous_week';
export const ONE_MONTH_AGO = 'previous_month';
export const NONE = 'none';
export const CUSTOM_DATE = 'custom_date';

export const LT = dateScopeType.lessThan;
export const GT = dateScopeType.greaterThan;
export const BETWEEN = dateScopeType.between;
export const EMPTY = dateScopeType.empty;
export const NOT_EMPTY = dateScopeType.notEmpty;

export const EXACT_DATE = dateScopeDynamicType.exactDate;
export const TODAY = dateScopeDynamicType.today;
export const LAST_X_DAYS = dateScopeDynamicType.lastXDays;
export const NEXT_X_DAYS = dateScopeDynamicType.nextXDays;
export const THIS_WEEK = dateScopeDynamicType.thisWeek;
export const LAST_WEEK = dateScopeDynamicType.lastWeek;
export const THIS_MONTH = dateScopeDynamicType.thisMonth;
export const LAST_MONTH = dateScopeDynamicType.lastMonth;

export const FILE_UPLOAD_LIMIT = 20000000;

export const GOAL_PARENT_QUERY_LEVEL = 10;

// Sorting
export const MANUAL_SORTING = 'manual_sorting';

// Cascade
export const CASCADE_CONTEXT_PLANNING = 'planning';
export const CASCADE_CONTEXT_PUBLISHED_VIEW = 'published_view';

// Errors
export const ERRORS = {
  SORT: {
    PARENT_NOT_EDITABLE: 'parent_not_editable',
    VIEW_NOT_EDITABLE: 'view_not_editable',
  },
  REALIGN: { MISSING_ACCESS_RIGHTS_FOR_GOAL: 'missing_access_rights_goal' },
};

// services
export const VIEWS_SERVICE = 'viewsService';

// static property keys
export const DIRECT_PROPERTY_DESCRIPTION_KEY = 'description';
export const DIRECT_PROPERTY_PROGRESS_KEY = 'progressMeasurement';
export const DIRECT_PROPERTY_CYCLE_KEY = 'goalCycle';
export const DIRECT_PROPERTY_PARENT_KEY = 'parents';

// slots
export const SLOTS = {
  DATA_LOADING: 'data_loading',
  LOCKED: 'locked',
  CYCLE_SELECTOR: 'cycle_selector',
  STRING_FILTER: 'string_filter',
  FILTER: 'filter',
  PROPERTIES: 'properties',
  GROUPING: 'grouping',
  SORTER: 'sorter',
  COMPARE_TO: 'compare_to_previous',
  WRAP_CELLS: 'wrap_cells',
  WRAP_GOAL_TITLES: 'wrap_goal_titles',
  SHOW_PARENTS: 'show_parents',
  CARD_WIDTH: 'card_width',
  EXPAND_BUTTONS: 'expand_buttons',
  CREATE: 'create',
};

// icons & emojis recent section
export const RECENT_ICONS_LOCALSTORAGE_PREFIX = 'recent_icons';
export const RECENT_EMOJIS_LOCALSTORAGE_PREFIX = 'recent_emojis';

// Datadog
export const CASCADE_TABLE_ITEM_DRAGGED = 'Cascade table item dragged';
export const CASCADE_TABLE_HEADER_WIDTH_CHANGED = 'Cascade table header width changed';
export const BLOCKED_BY_FEATURE_GATE = 'Blocked by Feature Gate';
export const TREE_VIEW_TOUCH_DEVICE_DETECTED = 'Tree view touch device detected';

export const DATADOG_ACTION_WHITELIST = {
  [CASCADE_TABLE_ITEM_DRAGGED]: null,
  [CASCADE_TABLE_HEADER_WIDTH_CHANGED]: null,
  [BLOCKED_BY_FEATURE_GATE]: null,
  [TREE_VIEW_TOUCH_DEVICE_DETECTED]: null,
};

// USER Status
export const USER_STATUS_NO_INVITATION = 'no_invitation';
export const USER_STATUS_INVITE_PENDING = 'invite_pending';
export const USER_STATUS_ACTIVE = 'active';
export const USER_DEMO = 'demo';

// SPACE Status
export const SPACE_STATUS_ARCHIVED = 'archived';
export const SPACE_STATUS_ACTIVE = 'active';
export const SPACE_STATUS_JOINED = 'joined';
export const SPACE_STATUS_ALL = 'all';

// Resource Center
export const RESOURCE_CENTER_OVERVIEW = 'overview';
export const RESOURCE_CENTER_ONBOARDING_GUIDE = 'onboarding-guide';
export const RESOURCE_CENTER_OKR_RESOURCES = 'okr-resources';
export const RESOURCE_CENTER_VIDEOS = 'videos';

export const RESOURCE_CENTER_ROUTER = 'resource_center_router';

export const APP_MODES = {
  requiresUpdate: 'requires update',
  foreignLogin: 'foreign login',
  login: 'login',
  app: 'app',
  maintenance: 'maintenance',
  loading: 'loading',
};

// collection of observable events: <namespace>.<eventName>
export const EVENTS = {
  SESSION: {
    LOGIN: 'SESSION.LOGIN',
    LOGOUT: 'SESSION.LOGOUT',
  },
  NAVIGATION: {
    FAVORITE_CREATED: 'NAVIGATION.FAVORITE_CREATED',
    FAVORITE_DELETED: 'NAVIGATION.FAVORITE_DELETED',
    PIN_CREATED: 'NAVIGATION.PIN_CREATED',
    PIN_DELETED: 'NAVIGATION.PIN_DELETED',
  },
  USER: {
    USERS_CREATED: 'USER.USERS_CREATED',
    USERS_DELETED: 'USER.USERS_DELETED',
    USERS_INVITED: 'USER.USERS_INVITED',
  },
  GOAL: {
    GOALS_CREATED: 'GOAL.GOALS_CREATED',
    GOALS_DELETED: 'GOAL.GOALS_DELETED',
    GOAL_ACTIVITIES_CREATED: 'GOAL.GOAL_ACTIVITIES_CREATED',
  },
  PLANNING: {
    PLANNING_CREATED: 'PLANNING.PLANNING_CREATED',
    GOALS_CREATED: 'PLANNING.GOALS_CREATED',
    GOALS_DELETED: 'PLANNING.GOALS_DELETED',
    GOALS_PUBLISHED: 'PLANNING.GOALS_PUBLISHED',
  },
  CHECKIN: {
    UPDATE_CREATED: 'CHECKIN.UPDATE_CREATED',
    UPDATE_DELETED: 'CHECKIN.UPDATE_DELETED',
    UPDATE_TEMPLATE_CREATED: 'CHECKIN.UPDATE_TEMPLATE_CREATED',
    UPDATE_TEMPLATE_DELETED: 'CHECKIN.UPDATE_TEMPLATE_DELETED',
    SCHEDULE_CREATED: 'CHECKIN.SCHEDULE_CREATED',
    SCHEDULE_DELETED: 'CHECKIN.SCHEDULE_DELETED',
  },
  VIEW: {
    VIEW_CREATED: 'VIEW.VIEW_CREATED',
    VIEW_DELETED: 'VIEW.VIEW_DELETED',
    ATLAS_VIEW_VISITED: 'VIEW.ATLAS_VIEW_VISITED',
    VIEW_PUBLISHED: 'VIEW.VIEW_PUBLISHED',
    VIEW_UNPUBLISHED: 'VIEW.VIEW_UNPUBLISHED',
    PUBLISHED_VIEW_VISITED: 'VIEW.PUBLISHED_VIEW_VISITED',
    GOAL_TREE_VIEW_VISITED: 'VIEW.GOAL_TREE_VIEW_VISITED',
  },
  PROPERTY: {
    GOAL_CYCLE_CREATED: 'PROPERTY.GOAL_CYCLE_CREATED',
    GOAL_CYCLE_DELETED: 'PROPERTY.GOAL_CYCLE_DELETED',
  },
  GRID_PAGE: {
    GRID_PAGES_CREATED: 'GRID_PAGE.GRID_PAGES_CREATED',
    GRID_PAGES_DELETED: 'GRID_PAGE.GRID_PAGES_DELETED',
    GRID_PAGE_TILES_CREATED: 'GRID_PAGE.GRID_PAGE_TILES_CREATED',
    GRID_PAGE_TILES_DELETED: 'GRID_PAGE.GRID_PAGE_TILES_DELETED',
  },
  INSIGHTS: {
    DASHBOARD_VISITED: 'DASHBOARD.DASHBOARD_VISITED',
    CUSTOM_DASHBOARD_VISITED: 'DASHBOARD.CUSTOM_DASHBOARD_VISITED',
  },
  INTERACTION: {
    REACTION_CREATED: 'INTERACTION.REACTION_CREATED',
    COMMENT_CREATED: 'INTERACTION.COMMENT_CREATED',
  },
  RESOURCE_CENTER: { RESOURCE_CENTER_VIEWED: 'RESOURCE_CENTER.RESOURCE_CENTER_VIEWED' },
  UPSELL: {
    FEATURE_GATE_VIEWED: 'UPSELL.FEATURE_GATE_VIEWED',
    CONTACT_SALES_VIEWED: 'UPSELL.CONTACT_SALES_VIEWED',
    CONTACT_SUPPORT_VIEWED: 'UPSELL.CONTACT_SUPPORT_VIEWED',
  },
  INTEGRATION: {
    INTEGRATION_ENABLED: 'INTEGRATION.INTEGRATION_ENABLED',
    INTEGRATION_DISABLED: 'INTEGRATION.INTEGRATION_DISABLED',
    DATASOURCE_CREATED: 'INTEGRATION.DATASOURCE_CREATED',
    DATASOURCE_DELETED: 'INTEGRATION.DATASOURCE_DELETED',
  },
  SETTINGS: { PROFILE_SETTINGS_VISITED: 'SETTINGS.PROFILE_SETTINGS_VISITED' },
};

export const CARD_SIZE_SMALL = 'small';
export const CARD_SIZE_MEDIUM = 'medium';
export const CARD_SIZE_LARGE = 'large';

export const DRAFT = 'Draft';

export const MAX_AUTO_LOAD_LIMIT = 100;

export const DEFAULT_SIDEBAR_WIDTH = 240;
