<template>
  <div class="my-workspace">
    <m-draggable
      :recreate-key="pins.length"
      class="_items"
      draggable-item-class="draggable-nav-item"
      dragover-item-class="draggable-nav-item"
      :drag-between-height="14"
      can-drag-over-top
      can-drag-over-bottom
      @set-drag-item="setDragItem"
      @over-bottom="setOverBottom"
      @over-top="setOverTop"
      @drag-drop="handleDrop"
      @cancel="cancelDragging"
    >
      <draggable-nav-item
        v-for="(pin, index) in pins"
        :key="pin.uid"
        :data-id="pin.uid"
        :dragging-over-bottom="dragItemId !== '' && draggingOverBottom.includes(pin.uid) && !draggingOverBottom.includes(dragItemId)"
        :dragging-over-top="index === 0 && dragItemId !== '' && draggingOverTop.includes(pin.uid)"
      >
        <collapsable-nav-items
          :item="itemForPin(pin)"
          :indentation-level="0"
          icon-size="18"
          hover-color="beige"
          :expand-svc="expandSvc"
        >
          <template #actions>
            <m-dropdown
              v-model:value="showSpaceMenu[pin.uid]"
              :title="$t('mainNavigation.spaceDropdownTitle')"
              block
              placement="bottomCenter"
              close-on-click
            >
              <m-btn
                icon="ellipsis"
                hide-border
                fab
                xs
                light
                @click="showSpaceMenu[pin.uid] = true"
              />
              <template
                v-if="pin.type === pinType.space"
                #overlay
              >
                <space-nav-menu
                  :space="pin.space"
                  :pin="pin"
                  @close="showSpaceMenu[pin.uid] = false"
                />
              </template>
              <template
                v-else
                #overlay
              >
                <m-card
                  list
                  no-padding
                >
                  <m-card-item
                    icon="pushpin-filled"
                    small
                    :loading="unpinLoading"
                    @click="unpinItem(pin)"
                  >
                    {{ $t('spaceNavMenu.unpin') }}
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </template>
        </collapsable-nav-items>
      </draggable-nav-item>
    </m-draggable>
    <div class="_all-items">
      <m-dropdown
        v-model:value="showSpaceNavigation"
        placement="topRight"
        block
        :relocate-key="relocateKey"
        :title="$t('myWorkspace.viewAll')"
      >
        <m-card-item
          full-width
          :focused="showSpaceNavigation"
          hover-color="beige"
          small
          class="_show-all"
          :text-style="{ fontSize: $fontSizes[2], fontWeight: '500', color: $colors.grey.base }"
          :style="$store.state.breakpoint.smAndDown ? { backgroundColor: 'transparent', borderBottom: 'none' } : {}"
          @click="handleShowSpaceNavigation"
        >
          {{ $t('myWorkspace.viewAll') }}
        </m-card-item>
        <template #overlay>
          <m-card
            no-padding
            class="_option-explorer"
          >
            <space-navigation
              :pins="pins"
              :title="spacePropertyTitle"
              @space-created="showSpaceNavigation = false"
            />
          </m-card>
        </template>
      </m-dropdown>
    </div>
  </div>
</template>

<script>
import CollapsableNavItems from '@/components/navigation/CollapsableNavItems.vue';
import DraggableNavItem from '@/components/navigation/DraggableNavItem.vue';
import SpaceNavMenu from '@/components/spaces/SpaceNavMenu.vue';
import SpaceNavigation from '@/components/navigation/SpaceNavigation.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import usePins from '@/composables/pin/pin';
import useProperties from '@/composables/property/property';
import usePropertyOptions from '@/composables/property-option/property-option';
import useSort from '@/composables/draggable/sort';
import useViewNavigator from '@/composables/saved-views/navigator';
import {
  GOAL_FEED_VIEW, GOAL_INSIGHT_VIEW,
  GOAL_VIEW,
  UPDATE_VIEW,
  USER_VIEW,
} from '@/route-params';
import { accessGroupFlag, moduleFlag, pinType, propertyApplication, routeName } from 'shared/constants.json';
import { buildIconFromEntity, isEmptyIcon } from 'shared/lib/icon';
import { computed } from 'vue';
import { getColor } from 'shared/lib/color-map';
import { getSpaceTitle } from '@/lib/space';
import { logCatch } from '@/lib/logger/logger';
import { sortByArray } from 'shared/lib/sort';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'MyWorkspace',
  props: {
    expandSvc: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const propertyOptionSvc = usePropertyOptions();
    const propertySvc = useProperties();
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    } = useSort();
    const { userLang, myTeams, loggedInUser } = useLoggedInUser();
    const { personalAppSettings, updateSingle } = usePersonalAppSettings(loggedInUser);
    const { pins, deletePinLoading, deletePin } = usePins();

    const { accountHasFeature, userHasRight } = useAccess();
    const { linkToRoute } = useViewNavigator();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      account: loggedInUserAccount,
      myTeams,
      userLang,
      dragItemId,
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      statePins: pins,
      unpinLoading: deletePinLoading,
      deletePin,
      linkToRoute,
      accountHasFeature,
      userHasRight,
      propertySvc,
      spaceProperty: propertySvc.spaceProperty,
      propertyOptionSvc,
      personalAppSettings,
      myWorkspaceOrder: computed(() => personalAppSettings.value.myWorkspaceOrder),
      updateSingle,
    };
  },
  components: {
    SpaceNavMenu,
    CollapsableNavItems,
    DraggableNavItem,
    SpaceNavigation,
  },
  data() {
    return {
      pinType,
      textByLang,
      getSpaceTitle,
      home: routeName.home,
      showSpaceNavigation: false,
      showActions: false,
      showSpaceMenu: {},
      relocateKey: 0,
    };
  },
  computed: {
    spacePropertyTitle() {
      const title = getSpaceTitle(textByLang(this.spaceProperty.label, this.userLang));
      if (this.userLang === 'en') {
        return title.toLowerCase();
      }

      if (!['Teams', 'Abteilungen', 'Organisationseinheiten'].includes(title)) {
        return 'Gruppen';
      }

      return title;
    },
    myTeamsPins() {
      return this.myTeams.map((o) => ({
        uid: o.uid,
        type: pinType.space,
        space: o,
      }));
    },
    pins() {
      const removablePins = this.statePins.filter((p) => {
        if (p.type === pinType.space) {
          return this.myTeams.find((t) => t.uid === p.space.uid) === undefined;
        }
        return false;
      });
      const nonRemovablePins = [
        ...this.statePins.filter((p) => {
          if (p.type === pinType.space) {
            return this.myTeams.find((t) => t.uid === p.space.uid) !== undefined;
          }
          return false;
        }),
        ...this.myTeamsPins.filter((t) => this.statePins.find((p) => p.type === pinType.space && p.space.uid === t.uid) === undefined),
      ];
      const pins = [
        ...removablePins.map((p) => ({
          ...p,
          removable: true,
        })),
        ...nonRemovablePins.map((p) => ({
          ...p,
          removable: false,
        })),
      ].map((p) => {
        if (p.type === pinType.space) {
          return { ...p, id: p.space.uid, property: this.spaceProperty };
        }
        return { ...p, id: p.uid };
      });
      pins.sort(sortByArray(this.myWorkspaceOrder.map((id) => ({ uid: id }))));
      return pins;
    },
  },
  methods: {
    handleShowSpaceNavigation() {
      this.relocateKey += 1;
      this.showSpaceNavigation = true;
    },
    handleDrop() {
      const newPins = this.dropItem(this.pins).map((p) => p.uid);
      this.saveMyWorkspaceOrder(newPins);
    },
    isActive(pin) {
      switch (pin.type) {
        case pinType.space:
          return this.$route.name === routeName.spaceDetails && this.$route.params.optionId === `${pin.space.uid}`;
        default:
          return false;
      }
    },
    saveMyWorkspaceOrder(order) {
      this.updateSingle({
        uid: this.personalAppSettings.uid,
        myWorkspaceOrder: order,
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    unpinItem(pin) {
      this.deletePin(pin).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    getTitle(pin) {
      switch (pin.type) {
        case pinType.space:
          return pin.space.title;
        default:
          return this.$t('general.untitled');
      }
    },
    getPinIcon(pin) {
      switch (pin.type) {
        case pinType.space:
          return buildIconFromEntity(pin.space);
        default:
          return '';
      }
    },
    getPinColor(pin) {
      switch (pin.type) {
        case pinType.space:
          return getColor(pin.space.color);
        default:
          return '';
      }
    },
    itemForPin(pin) {
      const pinIsUsedFor = (pin, usedFor) => {
        switch (pin.type) {
          case pinType.space: {
            return this.spaceProperty.usedFor.includes(usedFor);
          }
          default:
            return false;
        }
      };

      const subViews = [];
      if (this.accountHasFeature([moduleFlag.goals]) && pinIsUsedFor(pin, propertyApplication.goal)) {
        subViews.push({ title: this.account.goalSettings.featureNamePlural, toView: GOAL_VIEW });
      }
      if (this.accountHasFeature([moduleFlag.goals]) && pinIsUsedFor(pin, propertyApplication.goal)) {
        subViews.push({ title: this.$t('navigation.feed'), toView: GOAL_FEED_VIEW });
      }
      if (this.accountHasFeature([moduleFlag.updates]) && pinIsUsedFor(pin, propertyApplication.update)) {
        subViews.push({ title: this.$t('navigation.updatesExplorer'), toView: UPDATE_VIEW });
      }
      if (this.accountHasFeature([moduleFlag.goals]) && this.userHasRight([accessGroupFlag.goalDashboardView]) && pinIsUsedFor(pin, propertyApplication.goal)) {
        subViews.push({ title: this.$t('navigation.goalInsights'), toView: GOAL_INSIGHT_VIEW });
      }
      if (pinIsUsedFor(pin, propertyApplication.user)) {
        subViews.push({ title: this.$t('navigation.users'), toView: USER_VIEW });
      }
      let toName;
      let toSpace;
      if (pin.type === pinType.space) {
        toName = routeName.spaceDetails;
        toSpace = pin.space.uid;
      }
      const children = subViews.map(({ title, toView }) => this.linkToRoute(title, toName, toView, toSpace));

      const icon = this.getPinIcon(pin);
      return {
        id: `my-workspace_${pin.id}`,
        to: children[0].to,
        title: this.getTitle(pin),
        icon,
        showAvatar: isEmptyIcon(icon),
        avatarColor: this.getPinColor(pin),
        avatarShape: 'square',
        active: this.isActive(pin),
        children,
      };
    },
  },
  watch: {
    $route() {
      this.showSpaceNavigation = false;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._option-explorer {
    width: 40rem;

    @media (max-width: $screen-size-md) {
      width: 100vw;
    }
  }

  ._show-all {
    font-size: $font-size-2;

    ._new {
      margin-left: .6rem;
    }
  }
</style>
