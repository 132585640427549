import { RESULT, UID } from 'shared/api/query/constants';
import { customDatasourceProperty } from 'shared/constants.json';
import { dataSource as dataSourceConfig, user as userConfig } from 'shared/api/query/configs.json';

export const dataSourceChildren = [
  { attr: UID },
  { attr: dataSourceConfig.edges.name, default: '' },
  { attr: dataSourceConfig.edges.accessToken, default: '' },
  { attr: dataSourceConfig.edges.type },
  { attr: dataSourceConfig.edges.shared },
  { attr: dataSourceConfig.edges.creator, model: userConfig.model, children: [{ attr: UID }], default: null },
  { attr: dataSourceConfig.edges.apiServer, default: '' },
  { attr: dataSourceConfig.edges.apiToken, default: '' },
  { attr: dataSourceConfig.edges.refreshTokenCreatedAt, default: null },
  { attr: dataSourceConfig.edges.apiUser, default: '' },
  { attr: dataSourceConfig.edges.authenticationType, default: '' },
  { attr: dataSourceConfig.edges.oauthServer, default: '' },
  { attr: dataSourceConfig.edges.oauthv2ClientId, default: '' },
  { attr: dataSourceConfig.edges.oauthv2ClientSecret, default: '' },

  // jira
  { attr: customDatasourceProperty.jiraConnectionOk, default: false },
];
export const dataSourceChildrenWithExternalData = [
  ...dataSourceChildren,

  // spreadsheets
  { attr: customDatasourceProperty.spreadsheetDocuments, default: [] },
  { attr: customDatasourceProperty.spreadsheetError, default: '' },

  // asana
  { attr: customDatasourceProperty.asanaError, default: '' },
  { attr: customDatasourceProperty.asanaProjects, default: [] },
  { attr: customDatasourceProperty.asanaWorkspaces, default: [] },

  // salesforce
  { attr: customDatasourceProperty.salesforceReports, default: [] },
  { attr: customDatasourceProperty.salesforceError, default: '' },
];

export const dataSourceQuery = (dataSource) => [
  {
    alias: RESULT,
    func: { name: UID },
    uid: [dataSource.uid],
    model: dataSourceConfig.model,
    children: dataSourceChildrenWithExternalData,
  },
];
