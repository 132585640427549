import { COUNT, RESULT, UID } from 'shared/api/query/constants';
import { DateTime } from 'luxon';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { answerCountBy, answersBy } from '@/api/query/answers';
import {
  doConfirmRegisterCode,
  doDeleteLanguages,
  doGetChartData,
  doGetPublicAccountData,
  doLogoutUser,
  doOAuthLogin, doOAuthRegister, doRegisterViaEmail, dogma,
} from '@/api';
import { formById, formList, formWithCalculatedOrderItem } from 'shared/api/query/form';
import {
  form as formConfig,
  formTemplate as formTemplateConfig,
  surveyProcess as surveyProcessConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { formTemplateById, formTemplateListByAccount } from 'shared/api/query/form-template';
import { loggedInUserQuery } from '@/api/query/nebula/logged-in-user';
import { removeCookie } from 'shared/lib/cookie';
import { resetState } from '@/nebula/actions';
import { surveyProcessQuery } from '@/api/query/survey-process';

export const oauthRegister = ({ commit }, payload) => doOAuthRegister(payload)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('REGISTER_URLS', response.data);
    return response;
  });

export const oauthLogin = ({ commit }, payload) => doOAuthLogin(payload)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('LOGIN_URLS', response.data);
    return response;
  });

export const logout = ({ dispatch }) => doLogoutUser().then((response) => {
  dispatch('resetSession');
  return response;
});

export const resetSession = ({ commit }) => {
  removeCookie('foreign_login');
  commit('RESET_STATE');
  resetState({ commit });
  commit('USER_NOT_LOGGED_IN');
  commit('UNSET_FOREIGN_LOGIN');
  EventBus.$emit(EVENTS.SESSION.LOGOUT);
};

export const registerViaEmail = (_, payload) => doRegisterViaEmail(payload)
  .then((response) => response);

export const confirmRegisterCode = (_, payload) => doConfirmRegisterCode(payload)
  .then((response) => response);

export const getForms = ({ commit }, { loggedInUser }) => dogma.query(formList(loggedInUser)).then((response) => {
  if (response.status !== 200) {
    return response;
  }

  commit('FORMS_RETRIEVED', { forms: response.data[RESULT] });
  return response;
});

export const createForm = ({ commit }, { form }) => dogma.createSingle(
  form,
  formConfig.model,
  formById(0)[0].children,
).then((response) => {
  if (response.status !== 201) {
    return response;
  }

  commit('SURVEY_PROCESS_RETRIEVED', { surveyProcess: null });
  commit('FORM_CREATED', { form: { ...response.data } });
  return response;
});

export const getFormAndProcess = ({ commit }, { formId }) => dogma.query(
  [
    ...surveyProcessQuery({ formId, alias: 'process' }),
    ...formById(formId, RESULT),
  ],
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    if (response.data.process.length > 0) {
      commit('SURVEY_PROCESS_RETRIEVED', { surveyProcess: response.data.process[0] });
    } else {
      commit('SURVEY_PROCESS_RETRIEVED', { surveyProcess: null });
    }

    commit('FORM_RETRIEVED', { form: response.data[RESULT][0] });
    return response;
  });

export const getFormResultData = ({ commit }, { charts, userScopeTree, intervalConfig }) => doGetChartData({
  charts,
  userScopeTree,
  intervalConfig,
}).then((response) => {
  if (response.status !== 200) {
    return response;
  }

  commit('FORM_RESULT_DATA_RETRIEVED', { data: response.data });
  return response;
});

export const getFormAnswerCount = ({ commit }, { formId, userScopeTree, intervalConfig }) => dogma.query(answerCountBy(
  { formId, userScopeTree, intervalConfig },
)).then((response) => {
  if (response.status !== 200) {
    return response;
  }

  commit('FORM_RESULT_ANSWER_COUNT_RETRIEVED', {
    formId,
    count: response.data[RESULT][0][COUNT],
  });
  return response;
});

export const getFormAnswers = ({ commit }, {
  formId,
  userScopeTree,
  intervalConfig,
  page,
  itemsPerPage,
}) => dogma.query(answersBy(
  { formId, userScopeTree, intervalConfig },
  { page, itemsPerPage, countAlias: COUNT },
))
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('FORM_RESULT_ANSWER_COUNT_RETRIEVED', { count: response.data[COUNT][0][COUNT] });
    commit('FORM_RESULT_ANSWERS_RETRIEVED', { data: response.data[RESULT] });
    return response;
  });

export const updateEntityResourcePolicy = ({ commit }, { entity, model, entityById }) => dogma.updateSingle(
  entity,
  model,
  entityById()[0].children,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('RESOURCE_POLICY_UPDATED', { model, resourcePolicy: response.data.resourcePolicy });
    return response;
  });

export const updateFormAnalyticAccess = ({ commit }, { form, hookParameter }) => dogma.updateSingle(
  form,
  formConfig.model,
  formById(form.uid)[0].children,
  hookParameter,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('FORM_ANALYTIC_ACCESS_UPDATED', response.data);
    return response;
  });

export const getEntity = ({ commit }, { entityById, id, model, mutation = '' }) => dogma.query(
  entityById(id),
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    if (mutation !== '') {
      commit(mutation, { entity: response.data[RESULT][0], model });
      return response;
    }

    commit('ENTITY_RETRIEVED', { entity: response.data[RESULT][0], model });
    return response;
  });

export const getEntities = ({ commit }, { query, model, mutation = '' }) => dogma.query(
  query,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    if (mutation !== '') {
      commit(mutation, { entities: response.data[RESULT], model });
      return response;
    }

    commit('ENTITIES_RETRIEVED', { entities: response.data[RESULT], key: `${model}s` });

    return response;
  });

export const getEntitiesV2 = ({ commit }, { queries, model, mutation = '', options = {}, silent = false, headerOpts = null }) => {
  let header = {};
  if (headerOpts !== null) {
    header = headerOpts;
  }

  if (silent) {
    header = {
      Silent: 'true',
      ...header,
    };
  }
  return dogma.query(queries, { header })
    .then((response) => {
      if (response.status !== 200) {
        return response;
      }

      if (mutation !== '') {
        commit(mutation, { data: response.data, model, options });
        return response;
      }

      commit('ENTITIES_RETRIEVED_V3', { data: response.data, model, options });

      return response;
    });
};

export const createEntity = ({ commit }, {
  entity,
  model,
  entityById,
  mutation = '',
  storeKey,
  beforeCommit = () => ({}),
}) => dogma.createSingle(
  entity,
  model,
  entityById(entity.uid)[0].children,
)
  .then((response) => {
    if (response.status !== 201) {
      return response;
    }

    beforeCommit(response);
    if (mutation === '') {
      commit('ENTITY_CREATED', { entity: response.data, model });
      return response;
    }

    commit(mutation, { entity: response.data, storeKey, model });
    return response;
  });

export const createEntityV2 = ({ commit }, {
  entity,
  model,
  attributes,
  mutation = '',
  options,
  hookParameter,
}) => dogma.createSingle(
  entity,
  model,
  attributes,
  hookParameter,
)
  .then((response) => {
    if (response.status !== 201) {
      return response;
    }

    if (mutation !== '') {
      commit(mutation, { entity: response.data, model, options });
      return response;
    }

    commit('ENTITY_CREATED', { entity: response.data, model });
    return response;
  });

export const createEntities = ({ commit }, { entities, model, attributes, mutation = '' }) => dogma.mutate(
  entities,
  model,
  attributes,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    if (mutation === '') {
      commit('ENTITIES_CREATED', { entities: response.data, model });
      return response;
    }

    commit(mutation, { entities: response.data, model });
    return response;
  });

export const updateEntities = ({ commit }, { entities, model, attributes, mutation, options }) => dogma.update(
  entities,
  model,
  attributes,
).then((response) => {
  if (response.status !== 200) {
    return response;
  }

  commit(mutation, { entities: response.data, model, options });
  return response;
});

export const mutateEntities = ({ commit }, { entities, model, attributes, mutation = '', storeKey }) => dogma.mutate(
  entities,
  model,
  attributes,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    if (mutation !== '') {
      commit(mutation, { entities: response.data, model, storeKey });
      return response;
    }

    commit('ENTITIES_UPDATED', { entities: response.data, model });
    return response;
  });

export const mutateEntity = ({ commit }, { entity, model, attributes, mutation = '', storeKey }) => dogma.mutateSingle(
  entity,
  model,
  attributes,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    if (mutation !== '') {
      commit(mutation, { entity: response.data, storeKey });
      return response;
    }

    commit('ENTITY_UPDATED', { entity: response.data, model });
    return response;
  });

export const updateEntity = ({ commit }, {
  entity,
  model,
  entityById,
  mutation = '',
  storeKey,
  hookParameter = {},
}) => dogma.updateSingle(
  entity,
  model,
  entityById(entity.uid)[0].children,
  hookParameter,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    if (mutation !== '') {
      commit(mutation, { entity: response.data, storeKey, input: entity });
      return response;
    }

    commit('ENTITY_UPDATED', { entity: response.data, model, input: entity });
    return response;
  });

export const deleteEntity = ({ commit }, { entity, model, mutation = '', onDelete, headerOpts = {} }) => {
  const deletedAt = DateTime.local().toISO();
  return dogma.updateSingle(
    {
      ...entity,
      deletedAt,
    },
    model,
    [],
    null,
    { header: headerOpts },
  )
    .then((response) => {
      if (response.status !== 200) {
        return response;
      }

      if (typeof onDelete !== 'undefined') {
        onDelete();
      }

      if (mutation !== '') {
        commit(mutation, { entity, model });
        return response;
      }

      commit('ENTITY_DELETED', { entity, model });
      return response;
    });
};

export const deleteEntities = ({ commit }, { entities, model, mutation = '', onDelete, options }) => {
  const deletedAt = DateTime.local().toISO();
  return dogma.update(
    entities.map((e) => ({
      ...e,
      deletedAt,
    })),
    model,
    [],
  )
    .then((response) => {
      if (response.status !== 200) {
        return response;
      }

      if (typeof onDelete !== 'undefined') {
        onDelete();
      }

      if (mutation !== '') {
        commit(mutation, { entities, model, options });
        return response;
      }

      commit('ENTITIES_DELETED', { entities, model });
      return response;
    });
};

export const restoreEntity = ({ commit }, { entity, model, mutation = '', options, headerOpts = {} }) => dogma.updateSingle(
  {
    ...entity,
    softDeletedAt: null,
  },
  model,
  [],
  null,
  { header: headerOpts },
)
  .then((response) => {
    if (response.status !== 200) {
      throw new Error('failed to restoreEntities');
    }

    if (mutation !== '') {
      commit(mutation, { entity, model, options });
      return response;
    }
    return response;
  });

export const bulkMutate = ({ commit }, bulkPayloads) => dogma.bulkMutate(
  bulkPayloads.map((payload) => ({
    alias: payload.alias,
    nodes: payload.nodes,
    attributes: payload.attributes,
    model: payload.model,
    updateHookParameter: payload.updateHookParameter,
    createHookParameter: payload.createHookParameter,
  })),
).then((bulkResponse) => {
  if (bulkResponse.status !== 200) {
    return bulkResponse;
  }

  bulkPayloads.forEach(({ mutation = '', model, nodes, alias }) => {
    const response = { data: bulkResponse.data[alias] };

    if (mutation !== '') {
      commit(mutation, { entities: response.data });
      return;
    }

    const deletedNodes = nodes.filter((n) => n.deletedAt !== undefined);
    if (deletedNodes.length > 0) {
      commit('ENTITIES_DELETED', { entities: deletedNodes, model });
    }

    commit('ENTITIES_RETRIEVED_V2', { entities: response.data, model });
  });

  return bulkResponse;
});

export const deleteLanguage = ({ commit }, { ids, languages, model }) => doDeleteLanguages(
  { model, ids, languages },
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('LANGUAGE_DELETED', { languages, model });
    return response;
  });

export const deactivateSurveyProcess = ({ commit }, { toCancel, toCreate }) => dogma.mutate(
  [toCancel, toCreate],
  surveyProcessConfig.model,
  surveyProcessQuery({ formId: 0 })[1].children,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('SURVEY_PROCESS_CREATED', { surveyProcess: response.data.filter((sp) => sp.uid !== toCancel.uid)[0] });
    return response;
  });

export const createSurveyProcess = ({ commit }, { surveyProcess }) => dogma.createSingle(
  surveyProcess,
  surveyProcessConfig.model,
  surveyProcessQuery({ formId: 0 })[1].children,
)
  .then((response) => {
    if (response.status !== 201) {
      return response;
    }

    commit('SURVEY_PROCESS_CREATED', { surveyProcess: response.data });
    return response;
  });

export const updateSurveyProcess = ({ commit }, { surveyProcess }) => dogma.updateSingle(
  surveyProcess,
  surveyProcessConfig.model,
  surveyProcessQuery({ formId: 0 })[1].children,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('SURVEY_PROCESS_UPDATED', { surveyProcess: response.data });
    return response;
  });

export const createFormTemplate = ({ commit }, { formTemplate }) => dogma.createSingle(
  formTemplate,
  formTemplateConfig.model,
  formTemplateById(0)[0].children,
).then((response) => {
  if (response.status !== 201) {
    return response;
  }

  commit('FORM_TEMPLATE_CREATED', { formTemplate: response.data });
  return response;
});

export const getFormTemplatesByAccount = ({ commit }, { accountId, type, releasedOnly }) => dogma.query(
  formTemplateListByAccount({ accountId, type, releasedOnly }),
).then((response) => {
  if (response.status !== 200) {
    return response;
  }

  commit('FORM_TEMPLATES_RETRIEVED', { formTemplates: response.data[RESULT] });
  return response;
});

export const getFormTemplates = ({ commit }, { query }) => dogma.query(query()).then((response) => {
  if (response.status !== 200) {
    return response;
  }

  commit('FORM_TEMPLATES_RETRIEVED', { formTemplates: response.data[RESULT] });
  return response;
});

export const getFormTemplate = ({ commit }, { formTemplateId }) => dogma.query(
  formTemplateById(formTemplateId),
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('FORM_TEMPLATE_RETRIEVED', { formTemplate: response.data[RESULT][0] });
    return response;
  });

export const deleteForm = ({ commit }, { form }) => dogma.updateSingle(
  form,
  formConfig.model,
  [],
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('FORM_DELETE', { form });
    return response;
  });

export const surveyViewRequest = ({ commit }, { form }) => dogma.query(
  [
    {
      alias: 'form',
      model: formConfig.model,
      uid: [form.uid],
      func: { name: 'uid' },
      children: formWithCalculatedOrderItem(0)[0].children,
    },
    loggedInUserQuery(),
  ],
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    const user = response.data.user[0];
    commit('LOGGED_IN_USER_RETRIEVED', { user });

    commit('SURVEY_VIEW_FORM_RETRIEVED', { form: response.data.form[0] });
    return response;
  });

export const createAnswer = (store, { answer, model }) => dogma.createSingle(answer, model, []);

export const getUpdateTemplates = ({ commit }, { queries }) => dogma.query(
  queries,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    commit('UPDATE_TEMPLATES_RETRIEVED', { entities: response.data[RESULT] });
    return response;
  });

export const getEntityV2 = ({ commit }, { attributes, id, model, mutation = '' }) => dogma.query(
  [
    {
      alias: RESULT,
      func: { name: UID },
      uid: [id],
      model,
      children: attributes,
    },
  ],
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    if (mutation !== '') {
      commit(mutation, { entity: response.data[RESULT][0], model });
      return response;
    }

    commit('ENTITY_RETRIEVED_V2', { entity: response.data[RESULT][0], model });
    return response;
  });

export const updateEntityV2 = ({ commit }, {
  entity,
  model,
  attributes,
  mutation = '',
  options,
  hookParameter,
}) => dogma.updateSingle(
  entity,
  model,
  attributes,
  hookParameter,
)
  .then((response) => {
    if (response.status !== 200) {
      return response;
    }

    if (mutation !== '') {
      commit(mutation, { entity: response.data, model, options });
      return response;
    }

    commit('ENTITY_UPDATED', { entity: response.data, model });
    return response;
  });

export const deleteEntityV2 = ({ commit }, { entity, model, onDelete, mutation = '', options }) => {
  const deletedAt = DateTime.local().toISO();
  return dogma.updateSingle(
    {
      ...entity,
      deletedAt,
    },
    model,
    [],
  )
    .then((response) => {
      if (response.status !== 200) {
        return response;
      }

      if (typeof onDelete !== 'undefined') {
        onDelete();
      }

      if (mutation !== '') {
        commit(mutation, { entity, model, options });
        return response;
      }

      commit('ENTITY_DELETED', { entity, model });
      return response;
    });
};

export const getPublicAccountData = ({ commit }, { signUpPathName }) => doGetPublicAccountData(signUpPathName).then((response) => {
  if (response.status !== 200 && response.data.indexOf('could not find account') > -1) {
    commit('PUBLIC_ACCOUNT_DATA_RETRIEVED', { publicAccountData: null });
    return response;
  }

  if (response.status !== 200) {
    return response;
  }

  commit('PUBLIC_ACCOUNT_DATA_RETRIEVED', { publicAccountData: response.data });
  return response;
});

export const updateProductSurveyStatus = ({ commit }, entity) => dogma.updateSingle(
  entity,
  userConfig.model,
  [{ attr: UID }],
).then((response) => {
  if (response.status !== 200) {
    throw new Error('error updating product survey status');
  }

  commit('PRODUCT_SURVEY_STATUS_CHANGED', entity.productSurveyStatus);
});
