<template>
  <sign-in
    class="register"
  >
    <template
      #content
    >
      <div class="_head">
        <div class="_title">
          <div v-if="publicAccountData === null">
            <h1>
              {{ $t('register.title') }}
            </h1>
          </div>
          <div v-else>
            <h3>
              {{ $t('register.landingPageTitle') }}
            </h3>
            <h1>{{ publicAccountData.companyName }}</h1>
          </div>
        </div>
        <div
          v-if="!isSignUpLandingPage"
          class="_sub-title"
        >
          <div class="_perk">
            <m-icon
              class="_icon"
              type="check-mark"
            />
            <div>
              {{ $t('register.perk1') }}
            </div>
          </div>
          <div class="_perk">
            <m-icon
              class="_icon"
              type="check-mark"
            />
            <div>
              {{ $t('register.perk2') }}
            </div>
          </div>
          <div class="_perk">
            <m-icon
              class="_icon"
              type="check-mark"
            />
            <div>
              {{ $t('register.perk3') }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <sign-in-content
      class="_register"
    >
      <div class="_sso">
        <div
          v-if="publicAccountData === null || !publicAccountData.restrictGoogleLogin"
          class="_btn"
        >
          <m-btn
            block
            @click="goToGoogle"
          >
            <m-icon
              size="16"
              type="google-color"
              class="_sso-icon"
            />
            {{ $t('register.viaGoogle') }}
          </m-btn>
        </div>
        <div
          v-if="publicAccountData === null || !publicAccountData.restrictMicrosoftLogin"
          class="_btn"
        >
          <m-btn
            block
            @click="goToMicrosoft"
          >
            <m-icon
              size="16"
              type="microsoft-color"
              class="_sso-icon"
            />
            {{ $t('register.viaMicrosoft') }}
          </m-btn>
        </div>
      </div>
      <m-divider
        v-if="publicAccountData === null || (!publicAccountData.restrictPasswordLogin && (!publicAccountData.restrictGoogleLogin || !publicAccountData.restrictMicrosoftLogin))"
        small
      />
      <div
        v-if="publicAccountData === null || !publicAccountData.restrictPasswordLogin"
        class="_item"
      >
        <div class="_label">
          {{ $t('general.workMail') }}
        </div>
        <m-text-field
          v-model:value="email"
          auto-focus
          allow-clear
          :placeholder="$t('register.enterEmail')"
          @keydown.enter="submit"
        >
          <template #prefix>
            <m-icon
              type="mail"
              style="color: rgba(0, 0, 0, .25);"
            />
          </template>
        </m-text-field>
        <div
          v-if="errorMessage !== ''"
          class="_error-message"
        >
          {{ errorMessage }}
        </div>
      </div>
      <m-transition-expand>
        <div v-if="showConfirm">
          <div class="_hint">
            {{ $t('register.hint1') }} <br>
            {{ $t('register.hint2') }}
          </div>
          <div class="_item">
            <m-text-field
              v-model:value="code"
              auto-focus
              :placeholder="$t('register.codePlaceholder')"
              @keydown.enter="submitConfirm"
            />
          </div>
        </div>
      </m-transition-expand>
      <div class="_action">
        <m-btn
          v-if="publicAccountData === null || !publicAccountData.restrictPasswordLogin"
          :loading="loading"
          color="black"
          :button-style="{ width: '100%' }"
          :style="{ width: '100%' }"
          @click="submit"
        >
          {{ buttonText }}
        </m-btn>
      </div>
      <i18n-t
        keypath="register.terms"
        tag="div"
        class="_terms"
      >
        <template
          #tos
        >
          <m-link
            :href="TERMS_URL"
            class="_link"
            target="_blank"
            inherit-color
          > {{ $t('register.tos') }}
          </m-link>
        </template>
        <template
          #privacyPolicy
        >
          <m-link
            :href="PRIVACY_URL"
            class="_link"
            target="_blank"
            inherit-color
          > {{ $t('register.privacyPolicy') }}
          </m-link>
        </template>
      </i18n-t>
      <div
        v-if="publicAccountData === null"
        class="_bottom"
      >
        <div>
          {{ $t('login.haveAccount') }}
          <m-link
            :to="login"
            inherit-color
            underlined
          >
            {{ $t('login.logIn') }}
          </m-link>
        </div>
      </div>
    </sign-in-content>
  </sign-in>
</template>

<script>
import SignIn from '@/components/SignIn.vue';
import SignInContent from '@/components/SignInContent.vue';
import Url from 'domurl';
import useLogin from '@/composables/user/login';
import usePageVisits from '@/composables/page-visits';
import { EventBus } from '@/lib/event-bus';
import { PRIVACY_URL, TERMS_URL } from 'shared/transformers';
import { browserLanguage } from '@/lib/language';
import { emailRegex } from 'shared/lib/email-regex';
import { errorType } from '@/constants.json';
import { getQueryParam } from '@/lib/route';
import { logCatch } from '@/lib/logger/logger';
import { mapActions, mapState } from 'vuex';
import { routeName } from 'shared/constants.json';

export default {
  name: 'Register',
  components: { SignInContent, SignIn },
  setup() {
    const pageVisitSvc = usePageVisits();
    const { initializeUser } = useLogin(pageVisitSvc);
    return { initializeUser };
  },
  data() {
    return {
      loading: false,
      password: '',
      email: '',
      code: '',
      errorMessage: '',
      PRIVACY_URL,
      TERMS_URL,
      showConfirm: false,
    };
  },
  computed: {
    ...mapState({
      registerURLs: (state) => state.registerURLs,
      publicAccountData: (state) => state.publicAccountData,
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    src() {
      if (this.publicAccountData !== null && this.publicAccountData.companyImage !== null && this.isSignUpLandingPage) {
        return this.publicAccountData.companyImage.getURL;
      }

      return '';
    },
    login() {
      return { name: routeName.logIn };
    },
    buttonText() {
      if (this.showConfirm) {
        return this.$t('register.continueWithCode');
      }
      return this.$t('register.continueWithEmail');
    },
    queryEmail() {
      return getQueryParam(this.$route, 'email');
    },
    lang() {
      const lang = getQueryParam(this.$route, 'lang');
      if (lang !== '') {
        return lang;
      }
      return browserLanguage();
    },
    signUpLandingPage() {
      return this.$route.params.signUpPathName;
    },
    isSignUpLandingPage() {
      return typeof this.signUpLandingPage !== 'undefined';
    },
  },
  methods: {
    ...mapActions([
      'registerViaEmail',
      'confirmRegisterCode',
      'oauthRegister',
      'getPublicAccountData',
      'getLoggedInUser',
    ]),
    goToGoogle() {
      window.location.href = this.registerURLs.google;
    },
    goToMicrosoft() {
      window.location.href = this.registerURLs.microsoft;
    },
    submit() {
      if (this.showConfirm) {
        this.submitConfirm();
        return;
      }
      this.submitRegister();
    },
    submitRegister() {
      const reg = new RegExp(emailRegex());
      if (!reg.test(this.email.trim())) {
        this.errorMessage = this.$t('loginForm.notAValidEmail');
        return;
      }

      this.loading = true;
      this.registerViaEmail({
        email: this.email.trim(),
        lang: this.lang,
        createUserIfNotFound: this.publicAccountData === null,
      })
        .then((response) => {
          this.showConfirm = true;
          if (response.status !== 200 && this.isSignUpLandingPage) {
            if (response.data.indexOf('no such user') > -1) {
              this.$confirm({
                title: this.$t('register.emailNotFound'),
                okText: this.$t('general.okay'),
                okType: 'warning',
                hideCancel: true,
              });
              return;
            }
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }
          this.$router.push({ query: { ...this.$route.query, email: this.email } });
        }).finally(() => {
          this.loading = false;
        });
    },
    submitConfirm() {
      this.loading = true;
      this.confirmRegisterCode({
        email: this.email.trim(),
        code: this.code.trim(),
        lang: this.lang,
        createUserIfNotFound: this.publicAccountData === null,
      })
        .then((response) => {
          this.loading = false;
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }
          if (!response.data.isValid) {
            this.$showSnackbar({ color: 'error', message: this.$t('register.codeInvalid') });
            return;
          }
          this.initializeUser()
            .then(() => {
              if (this.$route.name === routeName.partnerSignUp) {
                this.$router.push({ name: routeName.onboarding, query: { partnerId: this.$route.params.partnerId } });
                return;
              }
              this.$router.push({ name: routeName.onboarding });
            }).catch(logCatch(() => {
              this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            }));
        });
    },
    initOAuth() {
      this.oauthRegister({
        originURL: window.location.href,
        customParams: { createUserIfNotFound: this.publicAccountData === null },
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });

      const url = new Url((new Url()).hash);
      if (typeof url.query.err !== 'undefined' && url.query.err !== null) {
        const error = JSON.parse(atob(url.query.err));
        if (error.type === errorType.emailNotMatching) {
          this.$showSnackbar({ color: 'error', message: `${this.$t('error.emailNotMatching')} ${error.value}` });
        } else {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      }
    },
    getAccountData() {
      this.getPublicAccountData({ signUpPathName: this.signUpLandingPage }).then((response) => {
        if (response.status !== 200 && response.data.indexOf('could not find account') > -1) {
          this.$showSnackbar({ color: 'error', message: this.$t('register.accountNotFound') });
          return;
        }
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.initOAuth();
      });
    },
  },
  watch: {
    queryEmail() {
      this.email = unescape(this.queryEmail);
    },
    email(val) {
      this.showConfirm = false;
      this.errorMessage = '';

      if (val === '') {
        const query = { ...this.$route.query };
        delete query.email;
        this.$router.push(query);
      }
    },
    signUpLandingPage() {
      if (!this.isSignUpLandingPage) {
        return;
      }
      this.getAccountData();
    },
  },
  mounted() {
    if (typeof this.$route.query.e !== 'undefined') {
      const e = this.$route.query.e;
      const reg = new RegExp(emailRegex());
      if (reg.test(e)) {
        this.email = e;
      }
    }
    if (this.$route.query.error === 'no_such_user' && this.isSignUpLandingPage) {
      this.$confirm({
        title: this.$t('register.emailNotFound'),
        okText: this.$t('general.okay'),
        okType: 'warning',
        hideCancel: true,
      });
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.replace('/');
      return;
    }

    if (this.$route.query.error === 'no_such_user' && this.isSignUpLandingPage) {
      const query = { ...this.$route.query };
      delete query.email;
      this.$router.push({ ...this.$route, query });
    }
    EventBus.$emit('logout');
    this.email = this.queryEmail;

    if (typeof this.$route.query.initialEmail !== 'undefined' && this.$route.query.initialEmail !== '') {
      this.email = unescape(this.$route.query.initialEmail);
    }
    if (this.isSignUpLandingPage) {
      this.getAccountData();
      return;
    }
    this.initOAuth();
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .register {
    width: 100%;

    ._head {
      padding: 0 3.2rem;
      margin-bottom: 3rem;

      ._icon {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 7rem;
          height: auto;
          border-radius: $default-border-radius;
        }
      }

      ._title {
        text-align: center;

        h1 {
          margin-top: 1rem;
        }
      }

      ._sub-title {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        ._perk {
          display: flex;
          align-items: center;
          margin-right: 2rem;
          margin-bottom: 1rem;

          ._icon {
            margin-right: .4rem;
          }
        }
      }
    }

    ._sso {
      ._btn {
        margin-bottom: .4rem;
        background-color: white;
      }

      ._sso-icon {
        margin-right: .8rem;
      }
    }

    ._item {
      margin-bottom: 1rem;

      ._label {
        font-size: $font-size-3;
        color: $font-color-secondary;
      }

      ._error-message {
        margin-top: .4rem;
        color: $error-color;
      }
    }

    ._hint {
      margin-bottom: 1rem;
      color: $font-color-secondary;
      text-align: center;
    }

    ._terms {
      margin-top: 3rem;
      font-size: $font-size-2;
      color: $font-color-secondary;
      text-align: center;

      ._link {
        text-decoration: underline;

        &:hover {
          color: $font-color-primary;
          cursor: pointer;
        }
      }
    }

    ._bottom {
      margin-top: 5rem;
      font-size: $font-size-4;
      color: $font-color-secondary;
      text-align: center;
    }
  }
</style>
