<template>
  <div
    class="grid-item"
  >
    <div class="_item-wrapper">
      <grid-item-content
        :data-id="JSON.stringify({uid: gridPageTile.uid})"
        :grid-row="props.gridRow"
        :grid-item="gridPageTile"
        :tile="gridPageTile"
        :style="itemStyle"
        :read-only="props.readOnly"
      />
      <div
        v-if="!isPrint"
        class="_column-gap"
      >
        <div
          v-if="dragZoneConfiguration.leftVisible"
          class="_dragzone-indicator-left _indicator"
        />
        <div
          v-if="dragZoneConfiguration.rightVisible"
          class="_dragzone-indicator-right _indicator"
        />
        <div
          v-if="!dragZoneConfiguration.enabled && canResize"
          :ref="(el) => columnResizeContainerRef = el"
          :class="['_column-resize-container', isResizingSelf ? '-resizing' : '', disableResizeHorizontally ? '-disabled' : '']"
          @mousemove="moveHighlight"
          @mousedown="initResize"
        >
          <div class="_indicator" />
          <div
            class="_indicator-highlight"
            :style="highlightStyle"
          />
        </div>
      </div>
    </div>

    <div
      v-if="dragZoneConfiguration.enabled"
      :style="{left: 0}"
      class="grid-item-drop _dragzone"
      :data-id="JSON.stringify({ uid: gridPageTile.uid, position: 'left' })"
    />
    <div
      v-if="dragZoneConfiguration.enabled"
      :style="{right: 0}"
      class="grid-item-drop _dragzone"
      :data-id="JSON.stringify({uid: gridPageTile.uid, position: 'right' })"
    />
  </div>
</template>

<script setup>
import GridItemContent from '@/components/custom-grid/GridItemContent.vue';
import useExport from '@/composables/export/export';
import useGridPage from '@/composables/grid-page/grid-page';
import useGridPageConfig from '@/composables/grid-page/grid-page-config';
import useSnackBar from '@/composables/snackbar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  gridPageTile: { type: Object, required: true },
  gridRow: { type: Object, required: true },
  containerWidth: { type: Number, required: true },
  readOnly: { type: Boolean, default: false },
  height: { type: Number, default: 0 },
});

const snackBar = useSnackBar();
const { t } = useI18n();
const gridPageService = useGridPage();

const gridPageTile = computed(() => gridPageService.tilesWithDiff.value[props.gridPageTile.uid]);

const row = computed(() => gridPageService.gridPageRows.value.find((row) => row.uid === props.gridRow.uid));

const itemCount = computed(() => row.value.tiles.length);

const tileIndex = computed(() => row.value.tileOrder.findIndex(({ uid }) => uid === gridPageTile.value.uid));

const { maxTiles, maxColumns, gapWidth, indicatorHighlightSize: highlightHeight } = useGridPageConfig();
const columnWidth = computed(() => props.containerWidth / maxColumns);
const canResize = computed(() => !props.readOnly && tileIndex.value !== itemCount.value - 1);
const disableResizeHorizontally = computed(() => row.value.tiles.length >= maxTiles);
const { isResizing } = gridPageService;

const isResizingSelf = computed(() => {
  if (!isResizing.value) {
    return false;
  }
  if (!gridPageService.tilesResizing.value.includes(gridPageTile.value.uid)) { return false; }
  const otherTileIDs = gridPageService.tilesResizing.value.filter((t) => t !== gridPageTile.value.uid);
  let smallestIndex = Math.min(otherTileIDs.map((oId) => row.value.tileOrder.findIndex(({ uid }) => uid === oId)));
  smallestIndex = smallestIndex !== -1 ? smallestIndex : gridPageService.maxTiles;
  return tileIndex.value < smallestIndex;
});

const cursorPos = ref({ init: null, next: null });

function handleMouseMove(event) {
  event.stopPropagation();
  cursorPos.value.next = event.clientX;
  gridPageService.addWidthDiff(gridPageTile.value, cursorPos.value.next - cursorPos.value.init, columnWidth.value);
}

function initResize(event) {
  if (disableResizeHorizontally.value === true) {
    return;
  }
  if (cursorPos.value.init !== null) {
    return;
  }

  cursorPos.value.init = event.clientX;
  document.addEventListener('mousemove', handleMouseMove);
  document.addEventListener('mouseup', commitResize);
}

function commitResize() {
  if (cursorPos.value.next !== null && cursorPos.value.init !== null) {
    gridPageService.commitWidth(
      gridPageTile.value,
      cursorPos.value.next - cursorPos.value.init,
      columnWidth.value,
    ).catch(() => { snackBar.error(t('error.default')); });
  }

  document.removeEventListener('mousemove', handleMouseMove);
  document.removeEventListener('mouseup', commitResize);

  cursorPos.value = { init: null, next: null };
}

const dragZoneConfiguration = computed(() => {
  const res = {
    enabled: false,
    leftVisible: false,
    rightVisible: false,
  };

  if (gridPageService.dragItem.value === null) {
    return res;
  }

  const currentTiles = row.value.tiles;
  // if the row is full and we are dragging outside of current row, we disable
  if (currentTiles.length === gridPageService.maxTiles && !currentTiles.map((t) => t.uid).includes(gridPageService.dragItem.value.uid)) {
    return res;
  }

  res.enabled = true;

  const dragTarget = gridPageService.dragTarget.value;

  if (dragTarget === null) {
    return res;
  }

  if (dragTarget.position === 'left') {
    const targetTile = gridPageService.gridPageTiles.value.find((t) => t.uid === dragTarget.uid);
    const dragTargetIndex = gridPageService.gridPageRows.value.find((row) => row.uid === targetTile.gridPageRow.uid).tileOrder.findIndex(({ uid }) => uid === targetTile.uid);
    const tileIndex = gridPageService.gridPageRows.value.find((row) => row.uid === gridPageTile.value.gridPageRow.uid).tileOrder.findIndex(({ uid }) => uid === gridPageTile.value.uid);

    if (row.value.uid === targetTile.gridPageRow.uid && dragTargetIndex === tileIndex + 1) {
      res.rightVisible = true;
      return res;
    }
  }

  if (dragTarget.uid !== gridPageTile.value.uid) {
    return res;
  }

  switch (dragTarget.position) {
    case 'left':
      if (tileIndex.value === 0) {
        res.leftVisible = true;
      }

      break;
    case 'right':
      res.rightVisible = true;
      break;
    default:
      break;
  }

  return res;
});

const { isPrint } = useExport();

const itemStyle = computed(() => {
  const fraction = gridPageTile.value.columns / maxColumns;

  const totalGapWidth = maxColumns * gapWidth;
  const overLappingGapWidth = (gridPageTile.value.columns - 1) * gapWidth;

  const usableSpace = props.containerWidth - totalGapWidth;
  const spaceForColumns = usableSpace * fraction;

  let total = overLappingGapWidth + spaceForColumns;

  if (gridPageTile.value.diff !== null) {
    total += gridPageTile.value.diff;
  }
  if (isPrint.value) {
    return { width: `${total}px`, flex: `0 0 ${total}px`, height: `${props.height}px`, marginRight: '2rem' };
  }

  return { width: `${total}px`, height: `${props.height}px` };
});

const columnResizeContainerRef = ref(null);
const columnResizeContainerRect = computed(() => {
  if (columnResizeContainerRef.value === null) return { y: 0, height: 0 };
  return columnResizeContainerRef.value.getBoundingClientRect();
});
const highlightStyle = ref({ height: `${highlightHeight}px`, top: 0 });
const moveHighlight = (event) => {
  if (disableResizeHorizontally.value === true) {
    return;
  }
  const minCenter = 0;
  const maxCenter = columnResizeContainerRect.value.height - highlightHeight;
  const containerTopY = columnResizeContainerRect.value.y;
  let center = event.clientY - containerTopY - highlightHeight / 2;
  if (center < minCenter) { center = minCenter; }
  if (center > maxCenter) { center = maxCenter; }
  highlightStyle.value = { height: `${highlightHeight}px`, top: `${center}px` };
};
</script>

<style  lang="scss" type="text/scss" scoped>
  .grid-item {
    width: 100%;
    position: relative;

    ._item-wrapper {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    ._dragzone {
      position: absolute;
      top: 2rem;
      height: calc(100% - 4rem);
      width: 50%;
    }

    ._indicator {
      position: relative;
      top: 0;
      left: .8rem;
      width: .4rem;
      height: 100%;
      border-radius: $border-radius-sm;
      opacity: .2;
    }

    ._indicator-highlight {
      position: relative;
      left: .3rem;
      width: .6rem;
      border-radius: $border-radius-sm;
    }

    ._dragzone-indicator-left {
      position: absolute;
      left: -1.2rem;
      background-color: map_get($blue, 'base');
    }

    ._dragzone-indicator-right {
      background-color: map_get($blue, 'base');
    }

    ._column-gap {
      width: 2rem;
      height: 100%;

      ._column-resize-container {
        height: 100%;
        width: 100%;
        display: flex;

        &.-disabled {
          &:hover {
            ._indicator {
              background-color: map_get($grey, 'base');
            }
          }
        }

        &:not(.-disabled) {
          &:hover, &.-resizing {
            cursor: ew-resize;

            ._indicator, ._indicator-highlight {
              background-color: map_get($blue, 'base');
            }
          }
        }
      }
    }
  }

</style>
