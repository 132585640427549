<template>
  <div
    :class="['update-editor', $store.state.breakpoint.smAndDown ? '-mobile' : '', edit ? '-editing' : '', modal ? '-modal' : '']"
  >
    <full-screen-spinner v-if="templatesLoading || loading" />
    <template v-else>
      <page-top-bar>
        <template #left>
          <div class="_title">
            <m-btn
              v-if="update !== null && !edit"
              hide-border
              small
              icon="open-as-page"
              :to="`/check-ins/${update.uid}`"
            >
              {{ $t('pageHeader.openAsPage') }}
            </m-btn>
          </div>
        </template>
        <template #actions>
          <div
            v-if="canEdit"
            class="_actions"
          >
            <m-dropdown
              v-model:value="showMenu"
              close-on-click
              :title="$t('general.actions')"
            >
              <m-btn
                icon="ellipsis"
                fab
                small
                hide-border
                @click="showMenu = true"
              />
              <template #overlay>
                <m-card
                  list
                  no-padding
                >
                  <m-card-item
                    v-if="disabled"
                    icon="edit"
                    @click="enableEditMode"
                  >
                    {{ $t('general.edit') }}
                  </m-card-item>
                  <m-card-item
                    icon="delete"
                    @click="onDeleteUpdate"
                  >
                    {{ $t('general.delete') }}
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </template>
      </page-top-bar>
      <m-content
        boxed-small
        :padding-bottom="12"
        :padding-top="6"
        padding-x="layout"
        :class="['_content', edit ? '-edit' : '']"
      >
        <div class="_top">
          <editable-page-header
            ref="header"
            :title="localUpdate.title"
            :icon="icon"
            :show-icon="!isEmptyIcon(icon) || emojiAdded"
            :disabled="disabled || (updateTemplate !== null && updateTemplate.title !== '') || (update !== null && update.title !== '')"
            :placeholder="$t('list.noTitle')"
            :auto-focus="localUpdate.title === ''"
            @change-title="updateTitle"
            @change-icon="updateIcon"
            @enter="displayEditor"
          >
            <template #actions>
              <div class="_header-actions">
                <m-btn
                  v-if="isEmptyIcon(icon) && !emojiAdded && edit"
                  hide-border
                  class="_item"
                  icon="smile"
                  light
                  small
                  @click="addEmoji"
                >
                  {{ $t('page.addEmoji') }}
                </m-btn>
              </div>
            </template>
          </editable-page-header>
        </div>
        <div class="_properties">
          <property-label
            :label="$t('general.creator')"
            :icon="iconBy(propertyType.user)"
          >
            <user-picker
              :value="creator"
              read-only
              hide-border
              full-width
            />
          </property-label>
          <property-label
            v-if="update !== null"
            :label="$t('general.createdAt')"
            :icon="iconBy(propertyType.date)"
          >
            <m-date-picker
              :value="fromISO(update.createdAt)"
              :locale="userLang"
              read-only
              :date-time="DateTime"
              hide-border
              full-width
            />
          </property-label>
          <property-values
            :read-only="!edit"
            :properties="updateProperties"
            :value="localUpdate.properties"
            @input="updateProps"
          />
        </div>
        <m-divider />
        <div
          :class="['_editor-wrapper', disabled ? '-disabled' : '']"
          @click="handleWrapperClick"
        >
          <template
            v-if="updateTemplate !== null || localUpdate.message !== null || showEditor || goalActivities.length > 0"
          >
            <m-editor
              ref="editor"
              :initial-value="localUpdate.message"
              :disabled="!edit"
              class="_editor"
              :placeholder="$t('updateEditor.startTyping')"
              :allowed-content="allowedContent"
              default-font-size="1.6rem"
              :ctrl-enter-handlers="[onCmdOrModEnter]"
              :mod-enter-handlers="[onCmdOrModEnter]"
              @input="updateMessage"
            />
            <template v-if="showGoalUpdates">
              <h6
                v-if="goalActivities.length > 0"
                class="_activities-header"
              >
                {{ $t('goalUpdateEditor.goalUpdates') }}
              </h6>
              <div class="_activities-body">
                <m-hover-menu
                  item-class="goal-update-form"
                  mousemove-area-class="update-editor"
                  :recreate-key="goalActivities.map(ga => ga.uid)"
                  @set-active-element="setActiveElement"
                >
                  <m-btn
                    fab
                    hide-border
                    small
                    icon="drag"
                    :style="{ cursor: 'grab', marginLeft: '-2rem', marginTop: '.3rem', maxWidth: 'fit-content' }"
                    light
                    @mousedown="handleMouseDownDragButton"
                  />
                </m-hover-menu>
                <m-draggable
                  ref="draggable"
                  ghost-item-class="goal-update-form"
                  dragover-item-class="goal-update-form"
                  can-drag-over-top
                  :drag-between-height="25"
                  can-drag-over-bottom
                  scroll-container-class="update-editor"
                  :recreate-key="sortedGoalActivities.map(ga => ga.uid)"
                  :disabled="disabled"
                  @set-drag-item="setDragItem"
                  @over-top="setOverTop"
                  @over-bottom="setOverBottom"
                  @drag-drop="handleDrop"
                  @cancel="cancelDragging"
                >
                  <div
                    v-for="(activity, index) in sortedGoalActivities"
                    :key="activity.sortKey"
                    class="_activity"
                  >
                    <div
                      v-if="index === 0 && dragItemId !== '' && draggingOverTop.includes(activity.sortKey)"
                      class="_drag-over-top"
                    />
                    <goal-activity-form
                      :activity="activity"
                      :disabled="!edit"
                      searchable
                      clearable
                      class="_activity"
                      @delete="deleteGoalActivity(activity)"
                      @change="updateActivity($event, activity)"
                    />
                    <div
                      v-if="dragItemId !== '' && draggingOverBottom.includes(activity.sortKey) && !draggingOverBottom.includes(dragItemId)"
                      class="_drag-over-bottom"
                    />
                  </div>
                </m-draggable>
                <template v-if="edit">
                  <add-goal-activity-btn
                    @add="addGoalActivities"
                    @add-reference="referenceGoalActivities"
                  />
                  <m-btn
                    v-if="goalsFromLastUpdate.length > 0"
                    hide-border
                    super-light
                    block
                    icon="history"
                    :button-style="{ justifyContent: 'flex-start', fontSize: $fontSizes[5], paddingLeft: '.6rem', marginBottom: '.2rem' }"
                    @click="addGoalActivities(goalsFromLastUpdate)"
                  >
                    <span :style="{ marginLeft: '1rem' }">
                      {{ $t('updateEditor.addGoalsFromLastWeek' ) }}
                    </span>
                  </m-btn>
                  <m-btn
                    v-for="group in goalsByUserProperties"
                    :key="group.property.uid"
                    hide-border
                    super-light
                    block
                    icon="user"
                    :button-style="{ justifyContent: 'flex-start', fontSize: $fontSizes[5], paddingLeft: '.6rem', marginBottom: '.2rem' }"
                    @click="addGoalActivities(group.goals)"
                  >
                    <span :style="{ marginLeft: '1rem' }">
                      {{ $t('updateEditor.addGoalsFromUserProp', { label: textByLang(group.property.label, userLang) }) }}
                    </span>
                  </m-btn>
                </template>
              </div>
            </template>
          </template>
          <div
            v-else-if="edit"
            class="_placeholder"
          >
            <div class="_heading">
              {{ $t('updateEditor.placeholderHeading') }}
            </div>
            <template-list-item
              v-for="template in templates"
              :key="template.uid"
              :template="template"
              :style="{ borderRadius: '.4rem', paddingLeft: 0, paddingRight: 0 }"
              super-light
              @select-template="addContent"
              @edit="openTemplate"
              @delete="deleteTemplate"
            />
          </div>
          <template v-if="update !== null && !edit">
            <div class="_bottom-actions">
              <add-reaction-btn
                v-if="reactions.length === 0"
                :update="update"
                show-text
                hide-border
                light
              />
              <div
                v-if="comments.length === 0"
                class="_add-comment"
              >
                <m-btn
                  icon="message"
                  hide-border
                  light
                  @click="addComment"
                >
                  {{ $t('updateFeedItem.addComment') }}
                </m-btn>
              </div>
            </div>
            <reaction-list
              :update="update"
              class="_reactions"
            />
            <comment-list
              ref="comments"
              :comments="comments"
              :update="update"
              collapse
              class="_comments"
              @write-comment="writeComment = true"
            />
          </template>
        </div>
      </m-content>
      <m-content
        v-if="edit"
        class="_footer"
        boxed-small
        padding-x="layout"
      >
        <div class="_inner">
          <div class="_actions">
            <m-btn
              class="_btn"
              hide-border
              @click="cancel"
            >
              {{ $t('general.cancel') }}
            </m-btn>
            <m-btn
              color="primary"
              :loading="submitLoading"
              @click="submit"
            >
              {{ $t('general.submit') }}
            </m-btn>
          </div>
        </div>
      </m-content>
    </template>
  </div>
</template>

<script>
import AddGoalActivityBtn from '@/components/updates/AddGoalActivityBtn.vue';
import AddReactionBtn from '@/components/reaction/AddReactionBtn.vue';
import CommentList from '@/components/comment/CommentList.vue';
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import GoalActivityForm from '@/components/goal/GoalActivityForm.vue';
import MEditor from '@/components/editor/MEditor.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import PropertyLabel from '@/components/PropertyLabel.vue';
import PropertyValues from '@/components/PropertyValues.vue';
import ReactionList from '@/components/reaction/ReactionList.vue';
import TemplateListItem from '@/components/page/TemplateListItem.vue';
import UserPicker from '@/components/UserPicker.vue';
import deleteMixin from '@/mixins/delete';
import navigationMixin from '@/mixins/router-navigation';
import useAccess from '@/composables/access/access';
import useComments from '@/composables/comments/comments';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import useProperties from '@/composables/property/property';
import useQuickActions from '@/composables/updates/quick-actions';
import useReactions from '@/composables/reactions/reactions';
import useSimpleGoalTree from '@/composables/goal/cascade/simple-goal-tree';
import useSnackBar from '@/composables/snackbar';
import useSort from '@/composables/draggable/sort';
import useStatusProperty from '@/composables/goal/status-property';
import useUpdateTemplates from '@/composables/update-templates/update-templates';
import useUpdates from '@/composables/updates/updates';
import { DateTime } from 'luxon';
import { buildIconFromEntity, isEmptyIcon, replaceIconOnEntity } from 'shared/lib/icon';
import { copy, shallowCopy } from 'shared/lib/copy';
import { current } from '@/lib/goal/progress';
import { editorNodeType, featureFlag, moduleFlag, propertyType, routeName } from 'shared/constants.json';
import { emptyValues, iconByType } from '@/lib/property';
import { findInArray } from 'shared/lib/array/array';
import { fromISO } from 'shared/lib/time';
import { guid } from 'shared/lib/uuid';
import { isEqual, uniqBy } from 'lodash-es';
import { logCatch } from '@/lib/logger/logger';
import { sortByArray } from 'shared/lib/sort';
import { textByLang } from 'shared/lib/language';
import { toRef } from 'vue';
import { trimEmptyLines } from '@/lib/editor/editor';
import { updateTemplate as updateTemplateConfig } from 'shared/api/query/configs.json';

export default {
  name: 'UpdateEditor',
  props: {
    update: {
      type: Object,
      default: () => null,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    templatesLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel', 'is-dirty', 'saved', 'create', 'deleted'],
  components: {
    TemplateListItem,
    EditablePageHeader,
    AddReactionBtn,
    PageTopBar,
    FullScreenSpinner,
    AddGoalActivityBtn,
    MEditor,
    PropertyValues,
    GoalActivityForm,
    UserPicker,
    PropertyLabel,
    CommentList,
    ReactionList,
  },
  setup(props) {
    const { goalCycles } = useGoalCycle();
    const snackbar = useSnackBar();
    const updateSvc = useUpdates();
    const updateTemplatesSvc = useUpdateTemplates();
    const { getTree } = useSimpleGoalTree();

    const { reactions } = useReactions(toRef(props, 'update'));

    const { updateProperties, userProperties } = useProperties();

    const { loggedInUser, userLang } = useLoggedInUser();
    const { accountHasFeature } = useAccess();
    const { properties } = useGoalProperty();
    const { comments } = useComments();
    const { selectedGoalCycles } = usePersistedGoalCycle();
    const goalsSvc = useGoals();
    const { goalsFromLastUpdate, init, goalsByUserProperties } = useQuickActions(goalsSvc, updateSvc, properties, selectedGoalCycles, loggedInUser);

    const { statusProperty: statusProp } = useGoalProperty();
    const { calculateInitialPropertyStatus } = useStatusProperty(statusProp, loggedInUser.language);

    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.mention,
      editorNodeType.goalMention,
    ];

    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    } = useSort('sortKey');

    return {
      getTree,

      userLang,
      snackbar,
      comments: props.update === null ? [] : comments({ updateId: props.update.uid }),
      accountHasFeature,
      createUpdate: updateSvc.createUpdate,
      updateUpdate: updateSvc.updateUpdate,
      deleteUpdate: updateSvc.deleteUpdate,
      createUpdateLoading: updateSvc.createUpdateLoading,
      updateUpdateLoading: updateSvc.updateUpdateLoading,
      goalsFromLastUpdate,
      initQuickActions: init,
      goalsByUserProperties,

      goalCycles,

      loggedInUser,

      updateTemplates: updateTemplatesSvc.updateTemplates,
      calculateInitialPropertyStatus,

      userProperties,
      updateProperties,

      reactions,
      allowedContent,

      dragItemId,
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
    };
  },
  data() {
    return {
      sortKeyCount: 0,
      goalActivitiesOrder: [],
      activeGoalActivityId: 0,
      fromISO,
      writeComment: false,
      DateTime,
      propertyType,
      emojiAdded: false,
      goalsLoading: false,
      loading: true,
      goals: [],
      selectedCycles: [],
      localGoalProps: [],
      filter: null,
      editMode: false,
      showMenu: false,
      showEditor: false,
      guid,
      localUpdate: { title: '', icon: '', image: null, properties: [], message: null },
      activitiesToCreate: [],
      activitiesToDelete: [],
    };
  },
  computed: {
    sortedGoalActivities() {
      return shallowCopy(this.goalActivities).sort(sortByArray(this.goalActivitiesOrder, 'sortKey'));
    },
    submitLoading() {
      return this.updateUpdateLoading || this.createUpdateLoading;
    },
    templates() {
      return [
        ...this.updateTemplates.map((t) => ({
          ...t,
          empty: t.template === null,
        })),
        {
          title: this.$t('updateEditor.emptyTemplate'),
          ...this.defaultPropertyValues,
          template: null,
          empty: true,
        },
      ];
    },
    updateTemplateId() {
      const id = parseInt(this.$route.query.templateId, 10);
      if (Number.isNaN(id)) {
        return 0;
      }

      return id;
    },
    notificationId() {
      const id = parseInt(this.$route.query.notificationId, 10);
      if (Number.isNaN(id)) {
        return 0;
      }

      return id;
    },
    title() {
      return this.localUpdate.title;
    },
    icon() {
      return buildIconFromEntity(this.localUpdate);
    },
    updateTemplate() {
      const id = parseInt(this.$route.query.templateId, 10);
      if (Number.isNaN(id)) {
        return null;
      }

      return findInArray({ haystack: this.updateTemplates, needle: id });
    },
    userValuesOfCommonProps() {
      const commonProps = uniqBy(this.updateProperties, this.userProperties, 'uid').map((p) => p.uid);
      return this.loggedInUser.values.filter((pv) => commonProps.includes(pv.property.uid));
    },
    defaultPropertyValues() {
      if (this.updateTemplate === null) {
        return uniqBy([...this.userValuesOfCommonProps, ...emptyValues(this.updateProperties)], (v) => v.property.uid);
      }
      return uniqBy([...this.userValuesOfCommonProps, ...this.updateTemplate.propertyValues, ...emptyValues(this.updateProperties)], (v) => v.property.uid);
    },
    creator() {
      if (this.update === null) {
        return [this.loggedInUser];
      }

      return [this.update.creator];
    },
    edit() {
      if (!this.disabled) {
        return true;
      }

      return this.canEdit && this.editMode;
    },
    showGoalUpdates() {
      return this.accountHasFeature([moduleFlag.goals]);
    },
    canEdit() {
      if (this.update === null || this.update.creator === null) {
        return false;
      }
      return this.update.creator.uid === this.loggedInUser.uid;
    },
    goalActivities() {
      return this.activitiesToCreate.filter((a) => typeof a.deletedAt === 'undefined');
    },
    goalsWithNewUpdates() {
      return this.activitiesToCreate.filter((a) => a['goalActivities|referenced'] !== true).map((activity) => activity.goal).filter((g) => g !== null);
    },
  },
  methods: {
    isEmptyIcon,
    setActiveElement(uid) {
      this.activeGoalActivityId = uid;
    },
    handleMouseDownDragButton(event) {
      const el = document.querySelector(`.goal-update-form[data-id="${this.activeGoalActivityId}"]`);
      if (el === null) {
        throw new Error('goal activity not found');
      }

      this.$refs.draggable.handleMouseDown(el)(event);
    },
    handleDrop() {
      this.goalActivitiesOrder = this.dropItem(this.goalActivitiesOrder);
    },
    textByLang,
    openTemplate(template) {
      this.$router.push({ name: routeName.updateTemplates, query: { templateId: template.uid } });
    },
    deleteTemplate(template) {
      this.showDeleteModal({
        entities: [template],
        model: updateTemplateConfig.model,
        mutation: 'UPDATE_TEMPLATES_DELETED',
      })();
    },
    handleWrapperClick() {
      if (this.showEditor || this.localUpdate.message !== null) {
        return;
      }

      this.displayEditor();
    },
    displayEditor() {
      this.showEditor = true;
      this.$nextTick(() => {
        this.$refs.editor.focus();
      });
    },
    addContent({ template }) {
      this.showEditor = true;
      this.addGoalActivitiesFromUpdateTemplate(template);
      const commonProps = uniqBy(this.updateProperties, this.userProperties, 'uid').map((p) => p.uid);
      for (let i = 0; i < this.localUpdate.properties.length; i++) {
        const propertyID = this.localUpdate.properties[i].property.uid;
        if (commonProps.includes(propertyID)) {
          continue;
        }
        const templatePV = template.propertyValues.find((pv) => pv.property.uid === propertyID);
        if (templatePV !== undefined) {
          this.localUpdate.properties[i] = { ...templatePV, uid: undefined };
        }
      }
      this.$nextTick(() => {
        this.$refs.editor.setContent(template.template);
        if (template.title !== '' && this.localUpdate.title === '') {
          this.localUpdate.title = template.title;
        }
        const icon = buildIconFromEntity(template);
        if (!isEmptyIcon(icon) && isEmptyIcon(this.icon)) {
          this.updateIcon(icon);
        }
        this.$refs.editor.focus();
      });
    },
    addComment() {
      this.showAddComment = false;
      this.$nextTick(() => {
        this.$refs.comments.add();
      });
    },
    iconBy(type) {
      return iconByType({ type });
    },
    cancel() {
      if (this.update !== null && !Number.isNaN(this.update.uid)) {
        this.editMode = false;
      }

      this.$emit('cancel');
    },
    enableEditMode() {
      this.showMenu = false;
      this.editMode = true;
    },
    addEmoji() {
      this.emojiAdded = true;
    },
    emitDirty() {
      if (!this.edit) {
        return;
      }
      this.$emit('is-dirty');
    },
    deleteGoalActivity(activity) {
      this.emitDirty();
      let index = -1;
      let value = null;
      this.activitiesToCreate.forEach((a, i) => {
        if (a.sortKey === activity.sortKey) {
          index = i;
          value = copy(a);
        }
      });
      if (value === null) {
        return;
      }

      if (activity.uid === undefined || activity.referenced === true || activity['goalActivities|referenced'] === true) {
        this.activitiesToCreate.splice(index, 1);
        const orderIndex = this.goalActivitiesOrder.findIndex((o) => o.sortKey === activity.sortKey);
        this.goalActivitiesOrder.splice(orderIndex, 1);
        return;
      }

      value.deletedAt = DateTime.local().toISO();
      this.activitiesToCreate.splice(index, 1);
      this.activitiesToDelete.push(value);

      const orderIndex = this.goalActivitiesOrder.findIndex((o) => o.sortKey === activity.sortKey);
      this.goalActivitiesOrder.splice(orderIndex, 1);
    },
    updateActivity(value, activity) {
      const index = this.activitiesToCreate.findIndex((a) => activity.sortKey === a.sortKey);
      this.emitDirty();
      this.activitiesToCreate.splice(index, 1, value);
    },
    updateTitle(value) {
      this.emitDirty();
      this.localUpdate.title = value;
    },
    updateIcon(icon) {
      this.emitDirty();
      replaceIconOnEntity(this.localUpdate, icon);
      if (this.isEmptyIcon(icon)) {
        this.emojiAdded = false;
      }
    },
    updateProps(properties) {
      this.emitDirty();
      this.localUpdate.properties = properties;
    },
    updateMessage(message) {
      if (isEqual(message, this.localUpdate.message)) {
        return;
      }
      this.emitDirty();
      this.localUpdate.message = message;
    },
    referenceGoalActivities(goalActivities) {
      this.emitDirty();
      const activityIDs = this.activitiesToCreate.map((a) => a.uid);
      const add = goalActivities.filter((a) => !activityIDs.includes(a.uid));
      add.forEach((a) => {
        this.activitiesToCreate.push({
          ...a,
          sortKey: a.uid,
          'goalActivities|referenced': true,
        });

        this.goalActivitiesOrder.push({ sortKey: a.uid });
      });
    },
    nextSortKey() {
      this.sortKeyCount += 1;
      return this.sortKeyCount;
    },
    addGoalActivitiesFromUpdateTemplate(template) {
      if (template === null || !template.prefillGoalActivities) {
        return;
      }
      const userPropertyIds = template.userProperties.map((u) => u.uid);
      const goalTypeIds = template.goalTypes.map((u) => u.uid);
      const goalsToAdd = this.goalsByUserProperties.reduce((res, next) => {
        if (!userPropertyIds.includes(next.property.uid)) {
          return res;
        }
        const goals = next.goals.filter((g) => {
          const goalTypePV = g.properties.find((pv) => pv.property.isGoalType);
          if (goalTypePV.selectedOptions.length === 0) {
            return false;
          }
          return goalTypeIds.includes(goalTypePV.selectedOptions[0].uid);
        });
        res.push(...goals);
        return res;
      }, []);
      if (goalsToAdd.length === 0) {
        return;
      }
      this.addGoalActivities(goalsToAdd);
    },
    addGoalActivities(goals) {
      this.emitDirty();
      const updatedGoalIds = this.goalsWithNewUpdates.map((g) => g.uid);

      const tree = this.getTree(goals);

      const now = DateTime.local().toISO();
      tree.forEach((g) => {
        if (updatedGoalIds.includes(g.uid)) {
          return;
        }

        const nextPropertyStatus = this.calculateInitialPropertyStatus(g);

        const sortKey = this.nextSortKey();
        this.activitiesToCreate.push({
          uid: undefined,
          sortKey,
          rid: sortKey,
          goal: g,
          current: current(g, g.cachedCurrent),
          properties: nextPropertyStatus,
          message: null,
          customCreatedAt: now,
        });
        this.goalActivitiesOrder.push({ sortKey });
      });
    },
    onCmdOrModEnter() {
      this.submit();
      return true;
    },
    submit() {
      this.localUpdate.message = trimEmptyLines(this.localUpdate.message);
      if (this.update === null) {
        this.create();
        return;
      }

      if (typeof this.$refs.editor !== 'undefined') {
        this.$refs.editor.setContent(this.localUpdate.message);
      }
      this.save();
    },
    transformGoalActivitiesOrder() {
      const filteredOrder = this.goalActivitiesOrder.filter((next) => this.activitiesToCreate.find((ac) => ac.sortKey === next.sortKey) !== undefined);
      return filteredOrder.map((next) => {
        const a = this.activitiesToCreate.find((ac) => (ac.sortKey === next.sortKey));
        if (a.uid === undefined) {
          return { rid: a.rid };
        }
        return { uid: a.uid };
      });
    },
    save() {
      const $t = (key) => this.$t(key);
      this.updateUpdate({
        ...this.localUpdate,
        goal: null,
        goalActivities: [...this.activitiesToCreate, ...this.activitiesToDelete],
        goalActivitiesOrder: this.transformGoalActivitiesOrder(),
      }, { ignoreResponse: false }).then((update) => {
        this.snackbar.success($t('success.updated'));
        this.localUpdate = update;
        this.$emit('saved', this.localUpdate);
        this.editMode = false;
      }).catch(logCatch(() => {
        this.snackbar.error();
      }));
    },
    create() {
      this.createUpdate({
        ...this.localUpdate,
        goal: null,
        goalActivities: this.activitiesToCreate,
        notification: { uid: this.notificationId },
        updateTemplate: { uid: this.updateTemplateId },
        goalActivitiesOrder: this.transformGoalActivitiesOrder(),
      }).then((update) => {
        this.localUpdate = { title: '', icon: '', image: null, properties: [], message: null };
        this.activitiesToCreate = [];
        this.$emit('create', update);
      }).catch(logCatch(() => {
        this.snackbar.error();
      }));
    },

    deleteUpdateEntity() {
      this.deleteUpdate(this.update).then(() => {
        this.$emit('deleted', this.update);
      }).catch(logCatch(() => {
        this.snackbar.error();
      }));
    },

    onDeleteUpdate() {
      const deleteMethod = this.deleteUpdateEntity;

      this.$confirm({
        title: this.$t('goalUpdateEditor.deleteUpdatePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
  watch: {
    templatesLoading() {
      this.localUpdate.properties = this.defaultPropertyValues;
      if (this.updateTemplate !== null) {
        this.localUpdate = {
          ...this.localUpdate,
          title: this.updateTemplate.title,
          icon: this.updateTemplate.icon,
          image: this.updateTemplate.image,
          message: copy(this.updateTemplate.template),
        };
      }
    },
  },
  created() {
    if (this.update !== null) {
      this.loading = false;
      this.localUpdate = copy(this.update);
      this.activitiesToCreate = copy(this.update.goalActivities.map((a) => ({
        ...a,
        sortKey: a.uid,
      })));
      this.goalActivitiesOrder = this.update.goalActivitiesOrder.length === 0 ? this.update.goalActivities.map((ga) => ({ sortKey: ga.uid })) : this.update.goalActivitiesOrder.map((k) => ({ sortKey: k.uid }));
      return;
    }

    this.initQuickActions().then(() => {
      this.addGoalActivitiesFromUpdateTemplate(this.updateTemplate);
      this.loading = false;
    });

    this.localUpdate.properties = this.defaultPropertyValues;
    if (this.updateTemplate !== null) {
      this.localUpdate = {
        ...this.localUpdate,
        title: this.updateTemplate.title,
        icon: this.updateTemplate.icon,
        image: this.updateTemplate.image,
        message: copy(this.updateTemplate.template),
      };
    }
  },
  mixins: [navigationMixin, deleteMixin],
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  $footer-height: 6.5rem;

  .update-editor {
    position: relative;
    display: grid;
    grid-template-rows: 4.5rem 1fr;
    grid-template-columns: 1fr;
    min-height: 100vh;

    &.-editing {
      grid-template-rows: 4.5rem 1fr $footer-height;
    }

    &.-modal {
      min-height: inherit;
    }

    &.-mobile {
      height: 100vh;
    }

    &:not(.-mobile) {
      &.-modal {
        height: calc(100vh - 14.4rem);
      }

      &:not(.-modal) {
        height: calc(100vh - 6.5rem);
      }
    }

    ._header {
      margin-bottom: 2rem;
    }

    ._properties {
      ._prop {
        display: flex;

        ._name {
          margin-left: .4rem;
        }
      }
    }

    ._content {
      max-height: 100%;
      overflow: auto;
    }

    ._editor-wrapper {
      min-height: 40vh;
      margin: 3rem 0 8rem;
      cursor: text;
      padding-bottom: 5rem;

      &.-disabled {
        cursor: default;
      }

      ._editor {
        margin-bottom: 2rem;
      }

      ._placeholder {
        color: $font-color-tertiary;

        ._heading {
          margin-bottom: 2rem;
        }
      }

      ._empty {
        color: $font-color-tertiary;
      }

      ._bottom-actions {
        display: flex;
        margin-bottom: 1.2rem;
      }

      ._reactions {
        margin-top: 1.4rem;
      }

      ._comments {
        margin-top: .2rem;
      }
    }

    ._activities-header {
      font-size: $font-size-2;
      font-weight: $font-weight-semibold;
      color: $font-color-secondary;
      text-transform: uppercase;
    }

    ._footer {
      z-index: 9;
      width: 100%;
      height: $footer-height;
      background-color: white;
      border-top: 1px solid $border-color;
      border-bottom-right-radius: $default-border-radius;
      border-bottom-left-radius: $default-border-radius;

      ._inner {
        display: flex;
        align-items: center;
        height: 100%;

        ._actions {
          display: flex;
          margin-left: auto;

          ._btn {
            margin-right: .4rem;
          }
        }
      }
    }

    ._activity {
      position: relative;
      margin-bottom: 1.2rem;
    }

    ._drag-over-top {
      position: absolute;
      top: -8px;
      right: 0;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._drag-over-bottom {
      position: absolute;
      right: 0;
      bottom: -8px;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }
  }
</style>
