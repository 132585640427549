<template>
  <m-sider
    :class="['main-navigation', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
    :width="width"
    :bordered="bordered"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="_content">
      <div
        class="_top"
      >
        <account-menu
          :show-close="hover"
          @toggle-sidebar="$emit('toggle-sidebar')"
        />
        <div
          v-if="goalsEnabled"
          class="_search-box"
        >
          <search-box />
        </div>
        <nav-items
          :items="topNavigation1"
        />
        <inbox
          v-if="goalsEnabled"
        />
        <nav-items
          :items="topNavigation2"
          hover-color="beige"
        />
        <template v-if="favorites.length > 0">
          <sub-heading
            :title="$t('mainNavigation.favorites')"
          />
          <favorites-nav-items :favorites="favorites" />
        </template>
        <sub-heading
          :title="$t('mainNavigation.myWorkspace')"
          class="_my-workspace"
        />
        <my-workspace
          :expand-svc="expandSvc"
        />
        <template v-if="tools.length > 0">
          <sub-heading
            :title="$t('mainNavigation.tools')"
          />
          <template
            v-for="item in tools"
            :key="item.title"
          >
            <collapsable-nav-items
              :item="item"
              :indentation-level="0"
              :expand-svc="expandSvc"
              hover-color="beige"
            />
          </template>
        </template>
      </div>
      <div
        class="_bottom"
      >
        <m-divider
          none
          class="_divider"
        />
        <trial-box
          v-if="showTrialBox"
          class="_trial-box"
          @hide="disableTrialBox"
        />
        <trash />
        <nav-item
          v-if="canInviteUsers"
          :title="$t('mainNavigation.invitePeople')"
          icon="user-group-add"
          hover-color="beige"
          @click="showInvitePeopleDialog"
        />
        <nav-item
          v-if="onboardingProgress !== 100"
          :title="$t('mainNavigation.onboarding')"
          icon="rocket"
          hover-color="beige"
          force-show-actions
          @click="showOnboarding"
        >
          <template #actions>
            <progress-tag
              :progress="onboardingProgress"
              :style="{ border: `1px solid ${$colors.blue.darken2}` }"
            />
          </template>
        </nav-item>

        <nav-item
          id="help-and-support"
          :title="$t('mainNavigation.helpAndSupport')"
          icon="question-circle"
          :style="{marginBottom: '1rem'}"
          hover-color="beige"
          @click="showResourceCenter"
        />
      </div>
      <m-dialog
        :value="showCreateInstantFeedbackRequest"
        max-width="60rem"
        hide-header
        hide-footer
        no-padding
        @close="closeCreateInstantFeedbackRequest"
      >
        <feedback-inquiry
          @cancel="closeCreateInstantFeedbackRequest"
          @close="closeCreateInstantFeedbackRequest"
        />
      </m-dialog>
      <m-dialog
        :value="showGiveFeedbackModal"
        max-width="60rem"
        hide-header
        hide-footer
        no-padding
        @close="closeGiveFeedbackModal"
      >
        <give-feedback
          @cancel="closeGiveFeedbackModal"
          @close="closeGiveFeedbackModal"
        />
      </m-dialog>
    </div>
  </m-sider>
</template>

<script>
import AccountMenu from '@/components/AccountMenu.vue';
import CollapsableNavItems from '@/components/navigation/CollapsableNavItems.vue';
import FavoritesNavItems from '@/components/navigation/FavoritesNavItems.vue';
import Inbox from '@/components/navigation/Inbox.vue';
import MSider from '@/components/layout/MSider.vue';
import MyWorkspace from '@/components/navigation/MyWorkspace.vue';
import NavItem from '@/components/navigation/NavItem.vue';
import NavItems from '@/components/navigation/NavItems.vue';
import ProgressTag from '@/components/ProgressTag.vue';
import SearchBox from '@/components/navigation/SearchBox.vue';
import SubHeading from 'shared/components/SubHeading.vue';
import Trash from '@/components/navigation/Trash.vue';
import TrialBox from '@/components/navigation/TrialBox.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useExpand from '@/composables/expand';
import useFavorites from '@/composables/favorite/favorite';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLocalStorage from '@/composables/local-storage/local-storage';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useMSTeams from '@/composables/msteams';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useOnboardingSteps from '@/composables/onboarding/onboarding-steps';
import useViewNavigator from '@/composables/saved-views/navigator';
import { DEFAULT_SIDEBAR_WIDTH } from '@/lib/constants';
import { DateTime } from 'luxon';
import { EventBus } from '@/lib/event-bus';
import { accessGroupFlag, moduleFlag, productPlan, routeName } from 'shared/constants.json';
import { computed, defineAsyncComponent } from 'vue';
import { mapState } from 'vuex';

export default {
  name: 'MainNavigation',
  props: {
    bordered: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: `${DEFAULT_SIDEBAR_WIDTH}px`,
    },
  },
  emits: ['toggle-sidebar'],
  setup() {
    const {
      accountHasFeature,
      userHasRight,
    } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { goalSettings } = useGoalSettings();
    const msTeamsSvc = useMSTeams();
    const { userLang } = useLoggedInUser();
    const identifier = { getId: (o) => o.id };
    const keyMaker = {
      getKey() {
        return computed(() => `${loggedInUserAccount.value.uid}_main_navigation_expand`);
      },
    };
    const localStorageSvc = useLocalStorage(localStorage, keyMaker);
    const expandSvc = useExpand(identifier, localStorageSvc.data);

    const accountSettingsSvc = useAccountSettings();
    const accessSvc = useAccess();
    const onboardingMarker = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    const { progress } = useOnboardingSteps(
      onboardingMarker,
      {},
      accountSettingsSvc,
      accessSvc,
    );

    const { favorites } = useFavorites();

    const { linkToRoute } = useViewNavigator();
    return {
      userLang,
      linkToRoute,
      accountHasFeature,
      userHasRight,
      account: loggedInUserAccount,
      isMsTeams: msTeamsSvc.isMsTeams,
      onboardingProgress: progress,
      expandSvc,
      canInviteUsers: userHasRight([accessGroupFlag.userWriteAccess]),
      favorites,
      goalSettings,
    };
  },
  components: {
    SearchBox,
    Inbox,
    Trash,
    CollapsableNavItems,
    MyWorkspace,
    FeedbackInquiry: defineAsyncComponent(() => import('@/components/feedback/FeedbackInquiry.vue')),
    TrialBox,
    NavItem,
    MSider,
    NavItems,
    AccountMenu,
    SubHeading,
    FavoritesNavItems,
    GiveFeedback: defineAsyncComponent(() => import('@/views/GiveFeedback.vue')),
    ProgressTag,
  },
  data() {
    return {
      drawer: true,
      showAccountOnboardingGuide: false,
      hover: false,
      trialBoxDisabled: false,
      routeName,
    };
  },
  computed: {
    ...mapState({ breakpoint: (state) => state.breakpoint }),
    showCreateInstantFeedbackRequest() {
      return this.$route.query.createInstantFeedbackRequest === 'true';
    },
    showGiveFeedbackModal() {
      return typeof this.$route.query.feedbackAnswerId !== 'undefined' && this.$route.query.feedbackAnswerId !== '';
    },
    showTrialBox() {
      if (this.breakpoint.smAndDown && this.isMsTeams) {
        return false;
      }

      return this.account.accountSettings.planId === productPlan.trial && !this.trialBoxDisabled;
    },
    goalsEnabled() {
      return this.accountHasFeature([moduleFlag.goals]);
    },
    topNavigation1() {
      const res = [];
      res.push({
        ...this.linkToRoute(this.$t('navigation.home'), routeName.home),
        id: 'home',
        icon: 'home',
      });
      return res;
    },
    topNavigation2() {
      return [
        {
          id: 'settings',
          icon: 'settings',
          title: this.$t('navigation.settings'),
          to: { name: routeName.profileSettings },
        },
      ];
    },
    tools() {
      const res = [];
      if (this.accountHasFeature([moduleFlag.surveys]) && !this.$store.state.breakpoint.smAndDown) {
        res.push({
          id: 'surveys',
          to: { name: routeName.form },
          title: this.$t('navigation.surveys'),
          icon: 'solution',
          active: [routeName.form].includes(this.$route.name),
          children: [
            {
              to: { name: routeName.form },
              title: this.$t('navigation.explorer'),
              active: [routeName.form].includes(this.$route.name),
            },
          ],
        });
      }
      if (this.goalsEnabled) {
        const explorer = this.linkToRoute(this.$t('navigation.explorer'), routeName.goalsExplorer);

        res.push({
          id: 'goals',
          icon: 'compass',
          title: this.goalSettings.featureNamePlural,
          to: explorer.to,
          active: [routeName.goalsExplorer, routeName.goalUpdateFeed].includes(this.$route.name),
          children: [
            explorer,
            this.linkToRoute(this.$t('goalsExplorer.feed'), routeName.goalUpdateFeed),
          ],
        });
      }
      if (this.accountHasFeature([moduleFlag.updates])) {
        const children = [
          this.linkToRoute(this.$t('navigation.explorer'), routeName.updatesExplorer),
          this.linkToRoute(this.$t('updatesList.schedules'), routeName.updateSchedules),
          this.linkToRoute(this.$t('updatesList.templates'), routeName.updateTemplates),
        ];
        res.push({
          id: 'updates',
          icon: 'history',
          title: this.$t('navigation.updatesExplorer'),
          to: children[0].to,
          active: [routeName.updatesExplorer, routeName.updateSchedules, routeName.updateTemplates].includes(this.$route.name),
          children,
        });
        // Force expansion when showCheckinTour to access the desired elements
        if (this.$route.query.showCheckinTour !== undefined && JSON.parse(this.$route.query.showCheckinTour) === true) {
          this.expandSvc.expandItem({ id: 'updates' });
        }
      }
      if (this.accountHasFeature([moduleFlag.plannings])) {
        res.push({
          id: 'planning',
          icon: 'plan',
          title: this.$t('navigation.plannings'),
          to: { name: routeName.planningList },
          active: [routeName.planningList].includes(this.$route.name),
        });
      }

      res.push({
        ...this.linkToRoute(this.$t('navigation.dashboards'), routeName.dashboards),
        id: 'dashboard',
        icon: 'appstore',
      });

      return res;
    },
  },
  methods: {
    showResourceCenter() {
      EventBus.$emit('show-resource-center');
    },
    showOnboarding() {
      EventBus.$emit('show-onboarding');
    },
    showInvitePeopleDialog() {
      EventBus.$emit('show-invite-users-dialog');
    },
    disableTrialBox() {
      localStorage.setItem('trialBoxDisabled', DateTime.local().toISO());
      this.trialBoxDisabled = true;
    },
    closeCreateInstantFeedbackRequest() {
      this.$router.push({ query: { ...this.$route.query, createInstantFeedbackRequest: '' } });
    },
    closeGiveFeedbackModal() {
      this.$router.push({ query: { ...this.$route.query, feedbackAnswerId: '' } });
    },
  },
  created() {
    if (typeof this.$route.query.showOnboarding !== 'undefined') {
      this.showAccountOnboardingGuide = true;
    }

    const trialBoxDisabled = localStorage.getItem('trialBoxDisabled');
    if (trialBoxDisabled === null) {
      return;
    }

    const d = DateTime.fromISO(trialBoxDisabled);
    if (d.isValid && d.diff(DateTime.local()).as('hours') < -24) {
      return;
    }

    this.trialBoxDisabled = true;
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .main-navigation {
    z-index: 1;
    background-color: $side-nav-background-color;

    ._content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;

      ._top {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 1.2rem;
        overflow: auto;

        ._search-box {
          padding: 0 1.4rem;
          margin: 0.8rem 0 0.8rem 0;
        }

        ._section {
          padding: 1.6rem 1.2rem .8rem;

          ._title {
            margin-bottom: 0;
            color: $font-color-secondary;
            text-transform: uppercase;
          }
        }
      }

      ._bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;

        ._divider {
          margin-bottom: .4rem;
        }

        ._trial-box {
          margin-bottom: .6rem;
        }

        ._new-btn {
          height: 5rem;
        }
      }
    }

    ._action {
      visibility: hidden;
    }

    &:hover {
      ._action {
        visibility: visible;
      }
    }

    &.-mobile {
      ._action {
        visibility: visible;
      }
    }
  }

</style>
